body {
    background-color: transparent;
}
.fsb-gallery {
	width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.fsb-gallery > ul {
	margin-bottom: 0;
	width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    transition: .5s;
	@media screen and (max-width: $size-max-3) {
		flex-wrap: wrap;
	}
}
.fsb-gallery > ul > li {
    float: left;
	margin: 15px;
	margin-top: 0;
	min-width: 300px;
    transition: .5s;
    flex-wrap: wrap;
	@media screen and (max-width: $size-max-1) {
		min-width: 270px;
		transition: .5s;
	}
	@media screen and (max-width: $size-max-2) {
		//min-width: 220px;
		transition: .5s;
		@media screen and (max-width: $size-max-3) {
			min-width: 250px;
			transition: .5s;
			@media screen and (max-width: $size-max-m-1) {
				min-width: 300px;
				transition: .5s;
			}
		}
	}
}
.fsb-gallery > ul > li a {
    border: 0 solid $color-white;
    border-radius: 1px;
    display: block;
    overflow: hidden;
    position: relative;
    float: left;
}
.fsb-gallery > ul > li a > img {
    -webkit-transition: -webkit-transform 0.5s ease 0s;
    -moz-transition: -moz-transform 0.5s ease 0s;
    -o-transition: -o-transform 0.5s ease 0s;
    transition: transform 0.5s ease 0s;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    height: 100%;
    width: 100%;
}
.fsb-gallery > ul > li a:hover > img {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
}
.fsb-gallery > ul > li a:hover .fsb-gallery-poster > img {
    opacity: 1;
}
.fsb-gallery > ul > li a .fsb-gallery-poster {
    background-color: rgba(0, 0, 0, 0.1);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: background-color 0.5s ease 0s;
    -o-transition: background-color 0.5s ease 0s;
    transition: background-color 0.5s ease 0s;
}
.fsb-gallery > ul > li a .fsb-gallery-poster > img {
    left: 50%;
    margin-left: -10px;
    margin-top: -10px;
    opacity: 0;
    position: absolute;
    top: 50%;
    -webkit-transition: opacity 0.5s ease 0s;
    -o-transition: opacity 0.5s ease 0s;
    transition: opacity 0.5s ease 0s;
}
.fsb-gallery > ul > li a:hover .fsb-gallery-poster {
    background-color: rgba(0, 0, 0, 0.151);
}
.fsb-gallery .justified-gallery > a > img {
    -webkit-transition: -webkit-transform 0.5s ease 0s;
    -moz-transition: -moz-transform 0.5s ease 0s;
    -o-transition: -o-transform 0.5s ease 0s;
    transition: transform 0.5s ease 0s;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    height: 100%;
    width: 100%;
}
.fsb-gallery .justified-gallery > a:hover > img {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
}
.fsb-gallery .justified-gallery > a:hover .fsb-gallery-poster > img {
    opacity: 1;
}
.fsb-gallery .justified-gallery > a .fsb-gallery-poster {
    background-color: rgba(0, 0, 0, 0.1);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: background-color 0.5s ease 0s;
    -o-transition: background-color 0.5s ease 0s;
    transition: background-color 0.5s ease 0s;
}
.fsb-gallery .justified-gallery > a .fsb-gallery-poster > img {
    left: 50%;
    margin-left: -10px;
    margin-top: -10px;
    opacity: 0;
    position: absolute;
    top: 50%;
    -webkit-transition: opacity 0.5s ease 0s;
    -o-transition: opacity 0.5s ease 0s;
    transition: opacity 0.5s ease 0s;
}
.fsb-gallery .justified-gallery > a:hover .fsb-gallery-poster {
    background-color: rgba(0, 0, 0, 0.5);
}
.fsb-gallery .video .fsb-gallery-poster img {
    height: 48px;
    margin-left: -24px;
    margin-top: -24px;
    opacity: 0.8;
    width: 48px;
}
.fsb-gallery.dark > ul > li a {
    border: 3px solid #04070a;
}
.home .fsb-gallery {
    padding-bottom: 80px;
}
