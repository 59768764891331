div.numbers-pagination {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid $color-grey-light-2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  &:nth-child(2) {
    justify-content: flex-end;
  }

  a {
    padding: 10px 15px;
    margin: 0;
    border: 1px solid rgb(243, 241, 241);
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    color: $color-yellow;
    transition: .5s;
    &:hover {
      transition: .5s;
      background: $color-2;
      color: $color-white;
      border: 1px solid $color-2;
      &.selected {
        background: $color-3;
        color: $color-white;
        border: 1px solid $color-2;
      }
    }
    &.selected {
      background: $color-yellow;
      color: $color-white;
      font-weight: 500;
      border: 1px solid $color-yellow;
    }
  }
}