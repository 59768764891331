footer {
    height: $size-footer-height;
    @extend .bg-color-footer;
    @extend .flex;
    @extend .flex-wrap;
    @extend .items-center;

    .footer-wrapper {
       // width: $size-footer-width-desktop;
        margin: 0 auto;
        @extend .flex;
        @extend .flex-wrap;
        @extend .items-center;
        @extend .justify-around;
        @extend .text-color-grey;
        @extend .font-footer;
        .copyright {
            font-size: 14px;
            margin-bottom: 10px;
        }
        .mediaweb {
            font-size: 18px;
            margin-bottom: 10px;
            a {
                @extend .text-color-grey;
                @extend .flex;
                font-size: 18px;
                @extend .items-center;
                img {
                    margin: -7px 0 0 0;
                }
                span { padding-left: 5px;}
                &:hover {
                    @extend .text-color-contrast;
                    color: $color-white;
                }
            }
        }
        
    }
    @media screen and (max-width: $size-xs-max) {
        height: 150px;
        .footer-wrapper {
            width: $size-footer-width-mobile;
            flex-direction: column;
        }
        
    }
}
