.slideshow-wrap {
    position: relative;
    //@extend .bg-color-black;
    #layerslider { display: block; }
    #layerslider-mobile { display: none; }
    @media screen and (max-width: $size-slider-breakpoint) {
        #layerslider { display: none; }
        #layerslider-mobile { display: block; }
    }

    @media screen and (max-width: $size-max-3) {
        margin-top: 0;
        transition: .5s;
    }

    .slideshow-next {
        right: 50px;
    }
    .slideshow-prev {
        left: 50px;
    }
    .subtitle {
        color: $color-white;
        letter-spacing: 0.3rem;
        text-transform: uppercase;
        font-size: $font-size-24;
        text-align: center;
        padding-bottom:0.5rem;
    }
    .title {        
        color: $color-black;
        font-size: $font-size-50;
        padding: 10px;
        text-align: center;
        line-height: 1;
        text-shadow: 2px 2px 5px $color-white,
                        -2px -2px 5px $color-white,
                        2px -2px 5px $color-white,
                        -2px 2px 5px $color-white;
        width: 100%;
        text-transform: uppercase;
        font-weight:700;
        font-style:normal;
        text-decoration:none;
        mix-blend-mode:normal;
        font-size:60px;
        transition: .5s;
        //height: 4.5vw;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;

        @media screen and (max-width: 1578px) {
            //font-size: 22px;
            //margin-top: -2px;
        }
        @media screen and (max-width: $size-max-2) {
            font-size: 50px;
            transition: .5s;
            @media screen and (max-width: $size-max-3) {
                font-size: 40px;
                transition: .5s;
                @media screen and (max-width: $size-max-4) {
                    font-size: 30px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        font-size: 22px;
                        //margin-top: -2px;
                    }
                }
            }
        }

        @media screen and (max-width: $size-max-m-4) {
            font-size: 22px;
            margin-top: 3px;
        }
    }
    .ls-l {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        //height: 49vw;
        background: url(../images/hero_texture_strip.jpg) no-repeat center center!important;
        height: 80px;
        background-size: cover;
    }
    .ls-l a {
        //width: auto;
    }


    .caption {
        position: absolute;
        top: $size-nav-height;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99;
        width: 100%;
        padding: 0;
        transition: .5s;
        @media screen and (max-width: $size-max-m-1) {
            top: $size-nav-height-mobile;
            transition: .5s;
        }

        div.row.text {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: url('../images/hero_texture_strip.jpg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            z-index: 9;
            h3 {
                color: $color-black;
                font-size: $font-size-50;
                padding: 10px;
                text-align: center;
                line-height: 1;
                text-shadow: 2px 2px 5px $color-white,
                             -2px -2px 5px $color-white,
                             2px -2px 5px $color-white,
                             -2px 2px 5px $color-white;
            }
        }
    }

    .ls-nav-next,
    .ls-nav-prev {
        margin-top: 27px !important;
    }

    @media screen and (max-width: $size-xs-max) {
        .ls-l a {
            font-size: $font-size-10;
            border-width: 1px;
            padding: 0.25rem;
        }
    }
    @media screen and (max-width: $size-slider-breakpoint) {
        //.title {
        //    font-size: 26px;
        //}
        //.subtitle {
        //    font-size: 16px;
        //}

    }
}
