.btn {
    background-color: $color-2;
    background: url(../images/main_bg_medium.jpg) no-repeat center center;
    background-attachment: fixed;
    background-size: cover;
    color: $color-white;
    border: 3px solid $color-white;
    border-radius: 0px;
    padding: 15px;
    font-size: 16px;
    width: 100%;
    span.btn-em {
        font-size: 20px;
        text-transform: uppercase;
        font-weigth: 600;
    }
    &:hover {
        color: $color-secondary;
    }
}