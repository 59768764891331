.bursts {
    width: 100%;
    //min-height: 450px;
    .bursts-container {
        @extend .flex;
        @media screen and (max-width: $size-max-m-2) {
            flex-direction: column;
        }

        .burst.burst-left,
        .burst.burst-right {
            min-height: 250px;
            overflow: hidden;
            img.img-burst {
                height: 100%;
                transition: .5s;
                @media screen and (max-width: $size-max-m-3) {
                    position: absolute;
                }
            }
            &:hover {
                img.img-burst {
                    transform: scale(1.5);
                    transition: .5s;
                }
            }
        }
        a {
            min-width: 300px;
        }

        .burst {
            //flex: 1;
            position: relative;
            .text-container, .overlay {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
            .text-container {
                padding: 1.25rem;
                .border {
                    border: 3px solid $color-white;
                    width: 100%;
                    height: 100%;
                    @extend .flex;
                    @extend .flex-column;
                    @extend .justify-center;
                    @extend .items-center;
                    transition: all 0.2s ease-out; // OUT effect

                    div.content-icons {
                        @extend .flex;
                        @extend .flex-column;
                        @extend .justify-center;
                        @extend .items-center;
                        img {
                            max-height: 70px;
                        }
                        img:nth-child(1) {
                            transition: all 0.5s ease; // IN effect
                            opacity: 1;
                        }
                        img:nth-child(2) {
                            transition: all 0.5s ease; // IN effect
                            position: absolute;
                            opacity: 0;
                        }
                    }
                }
            }
            .overlay {
                opacity: 0;
                transition: all 0.7s ease-out; // OUT effect
            }
            h3,h4 {
                color: $color-black;
                transition: all 0.2s ease-out; // OUT effect
            }
            h3 {
                @extend .font-1-normal;
                font-size: 32px;
                font-weight: 500;
                text-transform: none;
                text-align: center;
                text-transform: uppercase;
                margin-top: 25px;
                text-shadow: 0 0 6px rgba(255, 255, 255, 0.9), 0 0 12px rgba(255, 255, 255, 0.9);
                @media screen and (max-width: $size-max-2) {
                    font-size: 28px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 25px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-4) {
                            font-size: 22px;
                            transition: .5s;
                        }
                    }
                }
            }
            h4 {
                @extend .font-1-normal;
                font-size: 2.5vw;
            }
        }
        a:hover .burst {
            .overlay {
                opacity: 1;
                transition: all 0.3s ease; // IN effect
            }
            h3, h4 {
                color: $color-secondary;
                transition: all 0.5s ease; // IN effect
            }

            .border {
                //border-color: $color-secondary;
                transition: all 0.3s ease; // IN effect
                div.content-icons {
                    img:nth-child(1) {
                        opacity: 0;
                        transition: all 0.5s ease; // IN effect
                    }
                    img:nth-child(2) {
                        transition: all 0.5s ease; // IN effect
                        position: absolute;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.bursts.centrale {
    width: 100%;
    padding: 0;
    //min-height: 450px;
    .bursts-container {
        @extend .flex;
        @media screen and (max-width: $size-max-m-2) {
            flex-direction: column;
        }

        .burst.burst-central,
        .burst.burst-right {
            width: 100%;
            min-height: 250px;
            overflow: hidden;
            img.img-burst {
                height: 100%;
                transition: .5s;
                //width: 100%;
                @media screen and (max-width: $size-max-m-3) {
                    position: absolute;
                }
            }
            &:hover {
                img.img-burst {
                    transform: scale(1.4);
                    transition: .5s;
                }
            }
        }
        a {
            min-width: 300px;
            width: 100%;
        }

        .burst {
            //flex: 1;
            position: relative;
            .text-container, .overlay {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
            .text-container {
                padding: 1.25rem;
                .border {
                    border: 3px solid $color-white;
                    width: 100%;
                    height: 100%;
                    @extend .flex;
                    @extend .flex-column;
                    @extend .justify-center;
                    @extend .items-center;
                    transition: all 0.2s ease-out; // OUT effect

                    div.content-icons {
                        @extend .flex;
                        @extend .flex-column;
                        @extend .justify-center;
                        @extend .items-center;
                        img {
                            max-height: 70px;
                        }
                        img:nth-child(1) {
                            transition: all 0.5s ease; // IN effect
                            opacity: 1;
                        }
                        img:nth-child(2) {
                            transition: all 0.5s ease; // IN effect
                            position: absolute;
                            opacity: 0;
                        }
                    }
                }
            }
            .overlay {
                opacity: 0;
                transition: all 0.7s ease-out; // OUT effect
            }
            h3 {
                color: $color-white;
                transition: all 0.2s ease-out; // OUT effect
                transition: .5s;
            }
            h3 {
                @extend .font-1-normal;
                font-size: 32px;
                font-weight: 500;
                text-transform: none;
                text-align: center;
                text-transform: uppercase;
                margin-top: 25px;
                //text-shadow: 0 0 6px rgba(255, 255, 255, 0.9), 0 0 12px rgba(255, 255, 255, 0.9);
                text-shadow: 0 0 6px rgba(163, 163, 163, 0.9), 0 0 12px rgba(170, 170, 170, 0.9);
                @media screen and (max-width: $size-max-2) {
                    font-size: 28px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 25px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-4) {
                            font-size: 22px;
                            transition: .5s;
                        }
                    }
                }
                @media screen and (max-width: $size-max-m-1) {
                    font-size: 18px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-2) {
                        font-size: 18px;
                        transition: .5s;
                    }
                }
            }
            h4 {
                @extend .font-1-normal;
                color: $color-white;
                font-size: 25px;
                font-weight: 500;
                text-transform: none;
                text-align: center;
                text-transform: uppercase;
                margin-top: 15px;
                text-shadow: 0 0 6px rgba(163, 163, 163, 0.9), 0 0 12px rgba(170, 170, 170, 0.9);
                transition: all 0.7s ease-out; // OUT effect
                transition: .5s;

                @media screen and (max-width: $size-max-m-1) {
                    font-size: 20px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-2) {
                        font-size: 18px;
                        transition: .5s;
                    }
                }
            }
        }
        a:hover .burst {
            .overlay {
                opacity: 1;
                transition: all 0.3s ease; // IN effect
            }
            h3, h4 {
                color: $color-secondary;
                transition: all 0.5s ease; // IN effect
            }

            .border {
                //border-color: $color-secondary;
                transition: all 0.3s ease; // IN effect
                div.content-icons {
                    img:nth-child(1) {
                        opacity: 0;
                        transition: all 0.5s ease; // IN effect
                    }
                    img:nth-child(2) {
                        transition: all 0.5s ease; // IN effect
                        position: absolute;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

// .bursts.last-burst {
//
//     .burst.burst-right {
//         width: 100%;
//         height: 100%;
//         max-height: 300px;
//         overflow: hidden;
//         img.img-burst {
//             transition: .5s;
//         }
//
//         &:hover {
//             img.img-burst {
//                 transform: scale(1.5);
//             }
//         }
//     }
//
//     div.text-container {
//         div.border {
//             div.content-icons {
//                 img {
//                     //width: 250px;
//                     height: 110px!important;
//                     max-height: 100%!important;
//                 }
//             }
//
//             h3 {
//                 font-size: 25px;
//                 &:hover {
//                     color: $color-black!important;
//                 }
//             }
//         }
//     }
// }
// @media screen and (max-width: $size-slider-breakpoint) {
//     .bursts {
//         .bursts-container {
//             flex-direction: column;
//             .burst {
//                 h3 {
//                     font-size: 2rem;
//                 }
//                 h4 {
//                     font-size: 1rem;
//                 }
//             }
//         }
//     }
// }
