
/** to add hover effect on icon **/
.filter-hover {filter: invert(79%) sepia(60%) saturate(6121%) hue-rotate(20deg) brightness(89%) contrast(94%);}
.filter-black {filter: none;}
.filter-normal {filter: invert(100%) sepia(19%) saturate(254%) hue-rotate(303deg) brightness(115%) contrast(100%);}
.filter-blue {filter: brightness(0) saturate(100%) invert(17%) sepia(59%) saturate(2881%) hue-rotate(200deg) brightness(102%) contrast(89%);}
.filter-blue-clear {filter: brightness(0) saturate(100%) invert(36%) sepia(82%) saturate(1419%) hue-rotate(173deg) brightness(96%) contrast(101%);}

.icon {
    a {
        background-color: inherit;
        position: relative;
        .overlay {
            width: 100%;
            height: 100%;
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top:0;
            background-color: $bg-color-nav-highlight;
            mix-blend-mode: multiply;
            transition: all 0.2s ease-in;
            // for IE explorer fallback
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                background: transparentize($color-black, 0.5);
            }
            // For Edge fallback
            @supports (-ms-ime-align:auto) {
                background: transparentize($color-black, 0.5);
            }
        }
        &:hover {
            .overlay {
                opacity: 1;
                visibility: visible;
                transition: all 0.2s ease-out;
            }
        }
    }
}