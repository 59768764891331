// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
/* purgecss start ignore */
@import '../vendors/font-awesome/font-awesome';
// @import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
// @import '../vendors/parallax-scroll/normalize';
// @import '../vendors/parallax-scroll/main';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    .parallax, .parallax-slow, .parallax-fast {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}
a.btn-loi {
    width: 100px;
    position: fixed;
    left: 10px;
    z-index: 30;
    bottom: 15px;
    @media screen and (max-width: $size-xs-max) {
        width: 75px;
        left: initial;
        right: 10px;
    }
}
section.section-01-index {
    @extend .flex;
    @extend .flex-column;
    @extend .justify-center;
    @extend .items-center;
    background: url(../images/main_bg_parallax.jpg) no-repeat center center;
    background-attachment: fixed;
    background-size: cover;
    //min-height: 780px;;
    padding: 95px 0;
    padding-top: 70px;

    @media screen and (max-width: $size-max-m-1) {
        padding: 0 0;
        padding-bottom: 45px;
        transition: .5s;
    }

    div.container-fluid.row {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        padding: 0;
        transition: .5s;
        @media screen and (max-width: $size-max-m-1) {
            flex-direction: column;
            padding: 0 15px;
            transition: .5s;
            @media screen and (max-width: $size-max-m-3) {
                padding: 0 10px;
                transition: .5s;
            }
        }

        div.left-side-img,
        div.right-side-text {
            width: 50%;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                width: 100%;
                transition: .5s;
            }
        }

        div.left-side-img {
            border-bottom: 10px solid $color-yellow;
            padding-bottom: 0;

            div.content-img-left {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                padding: 0;
            }

            div.content-img-left.first-top,
            div.content-img-left.second-top {
                margin-top: 0;
                img.img-rsponsive.imgLeft {
                    /*padding-right: 100px;*/
                }
            }


            div.content-text-left {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                padding: 25px 0;
                h3.text-bottom {
                    color: $color-white;
                    font-weight: 500;
                    font-size: $font-size-36;
                    transition: .5s;
                    text-align: left;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 30px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            width: 100%;
                            font-size: 20px;
                            text-align: center;
                            transition: .5s;
                            padding-left: 5vw;
                            @media screen and (max-width: $size-max-m-2) {
                                font-size: 17px;
                                //text-align: center;
                                transition: .5s;
                            }
                        }
                    }
                }
            }
        }

        div.right-side-text {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            flex-direction: column;
            padding: 0 10vw;
            transition: .5s;
            @media screen and (max-width: $size-max-3) {
                padding: 0 3vw;
                transition: .5s;
            }

            hr {
                width: 105%;
                border: 1px solid $color-yellow;
                //margin-left: -35px;
            }

            ul.list-elements {
                width: 100%;
                list-style: none;
                list-style-type: none;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding: 25px 0;
                margin: 0;
                @media screen and (max-width: $size-max-m-1) {
                    align-items: center;
                    transition: .5s;
                }

                h3 {
                    text-align: left;
                    color: $color-yellow;
                    font-size: 25px;
                    text-transform: uppercase;
                    margin-bottom: 25px;
                    //margin-left: -15px;
                    @media screen and (max-width: $size-max-m-1) {
                        text-align: center;
                        transition: .5s;
                    }
                }

                li.item {
                    font-size: 20px;
                    font-weight: 400;
                    color: $color-white;
                    text-align: left;
                    text-transform: none;
                    line-height: 35px;
                    transition: .5s;
                    margin: 0;
                    @media screen and (max-width: $size-max-2) {
                        font-size: 18px;
                        line-height: 30px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            font-size: 18px;
                            line-height: 25px;
                            margin-bottom: 15px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                width: 100%;
                                font-size: 17px;
                                line-height: 22px;
                                text-align: center;
                                transition: .5s;
                                padding: 0;
                            }
                        }
                    }

                    i {
                        margin-right: 10px;
                    }
                }
            }

            div.text-box {
                width: 100%;
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                p {
                    text-align: justify;
                    color: $color-white;
                    font-weight: 300;
                    font-size: 20px;
                    line-height: 25px;
                    margin-top: 25px;
                    transition: .5s;
                    width: 100%;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 17px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            text-align: center;
                            transition: .5s;
                        }
                    }
                }
                p.text-center {
                    text-align: justify;
                }
                p.w-100 {
                    width: 100%;
                }
            }

            div.content-icons-bottom {
                width: 100%;
                padding: 0;
                display: flex;
                align-items: center;
                margin-top: 35px;
                .image {
                    width: 50%;
                    padding: 0 25px 25px 25px;
                    img {
                        @media screen and (max-width: $size-max-m-2) {
                            min-width: 55px;
                        }
                    }
                    @media screen and (max-width: $size-max-m-2) {
                        width: 100%;
                        padding-bottom: 35px;
                    }
                }
            }

            ul.liste-section {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                margin-top: 45px;
                width: 100%;

                li.item {
                    width: 100%;
                    text-align: left;
                    color: $color-white;
                    text-transform: none;
                    font-size: 20px;
                    line-height: 44px;
                    font-weight: 400;
                    transition: .5s;
                }
            }
        }
    }
    &:nth-child(2) {
        .right-side-text {
            margin-bottom: -65px;
        }
    }
}

section.section-01-index.pad-extrat {
    padding: 115px 0;
    padding-bottom: 75px;
    &:nth-child(1) {
        padding-bottom: 115px;
    }
    @media screen and (max-width: $size-max-m-1) {
        padding-bottom: 0;
    }

    div.container-fluid {
        align-items: flex-start;

        h3.text-bottom {
            @media screen and (max-width: $size-max-m-1) {
                //text-align: center!important;
            }
        }

        div.left-side-img.btm-none {
            display: flex;
            border-bottom: 0;
            margin-bottom: 35px;
            flex-direction: column;
            align-items: center;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                align-items: center;
                transition: .5s;
            }
            div.content-text-left {
                h3.text-bottom {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    //padding-right: 4vw;
                }
            }
            div.content-img-left {
                img {
                    border-bottom: 10px solid $color-yellow;
                }
            }
        }

        div.right-side-text {
            padding: 0 0vw;
            transition: .5s;
            width: 50%;
            padding-left: 8vw;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                width: 100%;
                padding: 6vw;
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {
                    padding: 4vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-4) {
                        padding: 2vw;
                        transition: .5s;
                    }
                }
            }

            p {
                padding-right: 5vw;
            }

            ul {
                li {
                    padding-right: 5vw;
                }
            }
        }
    }

    h3.mini-title {
        color: $color-yellow;
        font-size: 25px;
        width: 100%;
        text-align: left;
        transition: .5s;
        padding-top: 30px;
        @media screen and (max-width: $size-max-m-1) {
            text-align: center;
            transition: .5s;
        }
    }
}

section.section-01-index.pad-extrat.first-elt {
    padding-bottom: 115px;
}

section.section-02-index {
    @extend .flex;
    @extend .flex-column;
    @extend .justify-center;
    @extend .items-center;
    background: url(../images/burst_quebec_bg.jpg) no-repeat center center;
    background-size: cover;
    padding: 45px 0;

    div.container-fluid.row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        a.link-burt-separated {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;

            div.content-img-burst {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                img:nth-child(1) {
                    transition: all 0.5s ease; // IN effect
                    opacity: 1;
                }
                img:nth-child(2) {
                    transition: all 0.5s ease; // IN effect
                    position: absolute;
                    opacity: 0;
                }
            }
            h3 {
                @extend .font-1-normal;
                font-size: 25px;
                font-weight: 500;
                text-transform: none;
                text-align: center;
                text-transform: uppercase;
                margin-top: 25px;
                color: $color-black;
                text-shadow: 0 0 6px rgba(255, 255, 255, 0.9), 0 0 12px rgba(255, 255, 255, 0.9);
            }
            &:hover {
                h3 {
                    color: $color-secondary;
                    transition: all 0.5s ease; // IN effect
                }
                div.content-img-burst {
                    img:nth-child(1) {
                        transition: all 0.5s ease; // IN effect
                        opacity: 0;
                    }
                    img:nth-child(2) {
                        transition: all 0.5s ease; // IN effect
                        position: absolute;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

section.section-02 {
    @media screen and (max-width: $size-max-1) {
        //padding-top: 85px;
        @media screen and (max-width: $size-max-m-1) {
            padding-top: 0;
        }
    }
}

.right-side-text.section-right-2 {
    padding-right: 4vw!important;
    transition: .5s;
    @media screen and (max-width: $size-max-3) {
        padding-right: 0vw!important;
        transition: .5s;
    }
    .liste-section {
        width: 85%!important;
        @media screen and (max-width: $size-max-1) {
            width: 95%!important;
            transition: .5s;
            @media screen and (max-width: $size-max-2) {
                width: 85%!important;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    width: 100%!important;
                    transition: .5s;
                }
            }
        }
        li {
            font-size: 20px!important;
            @media screen and (max-width: $size-max-4) {
                font-size: 16px!important;
                line-height: 30px!important;
                transition: .5s;
                @media screen and (max-width: $size-max-m-3) {
                    font-size: 16px!important;
                    line-height: 25px!important;
                    margin-bottom: 15px;
                    transition: .5s;
                }
            }
        }
    }
}

.title-section {
    text-transform: uppercase;
    color: $color-yellow;
    text-align: center;
    font-size: 40px;
    font-weight: 500;
    width: 100%;

    @media screen and (max-width: $size-max-2) {
        font-size: 35px;
        transition: .5s;
        @media screen and (max-width: $size-max-3) {
            font-size: 35px;
            transition: .5s;
            @media screen and (max-width: $size-max-4) {
                font-size: 30px;
                transition: .5s;
            }
            @media screen and (max-width: $size-max-m-1) {
                font-size: 25px;
                text-align: left;
                margin-top: 25px;
                transition: .5s;
                @media screen and (max-width: $size-max-m-3) {
                    font-size: 19px;
                    text-align: center;
                    margin-top: 25px;
                    transition: .5s;
                }
            }
        }
    }
}


.section-map {
  @extend .flex;
  @extend .flex-column;
  @extend .justify-center;
  @extend .items-center;
  background-image: url('../images/section_04_bg.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 45px 0;

  div.container-fluid.row {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      .content-box {
        img {
          margin: 15px auto;
        }
        h3 {
          color: $color-yellow;
          text-shadow: 0 0 10px $color-black;
          font-size: $font-size-30;
          font-weight: 500;
        }
      }
    }
}

section.recommended-content-gerable {
	position: absolute;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
	div.container {

		div.modal-PDF-FSB {
			padding: 0;
			justify-content: center;
			align-items: center;
		}

		div.body-content {
			width: 100%;
			background: $color-white;
			padding: 0;
			@media screen and (max-width: 1455px) {
				padding: 0;
            }
            img {
                width: 100%;
                transition: .5s;
            }
			p {
				color: #000;
				font-weight: 400;
				font-size: 20px;
                line-height: 32px;
                padding: 25px;
                text-align: center;
				@media screen and (max-width: 1455px) {
                    padding: 15px;
                    @media screen and (max-width: 480px) {
                        font-size: 16px;
                        line-height: 30px;
                    }
                }
			}
        }

        div.footer-popup {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            background: $color-white;
            padding-right: 15px;
            margin-top: -20px;
            height: 20px;
            width: 100%;
            label {
                color: #CCC;
                font-weight: 400;
                text-align: right;
                font-size: 10px;
                width: 100%;
                margin: 0;
            }
        }
	}

	/* The Modal (background) */
	.modal-gerable {
		display: none; /* Hidden by default */
		position: fixed; /* Stay in place */
		z-index: 1; /* Sit on top */
		padding-top: 100px; /* Location of the box */
		left: 0;
		top: 0;
		width: 100%; /* Full width */
		height: 100%; /* Full height */
		overflow: auto; /* Enable scroll if needed */
		background-color: rgb(0,0,0); /* Fallback color */
		background-color: rgba(0,0,0,0.76); /* Black w/ opacity */
	}

	/* Modal Content */
	.modal-content-gerable {
		background-color: #000;
		margin: auto;
		padding: 0;
		border: 0 solid #888;
		width: 60%;
		max-width: 1170px;
		@media screen and (max-width: $size-max-m-2) {
            transition: .5s;
			max-width: 100%;
		    width: 85%;
		}

		div.header-modal-PDF {
			height: 75px;
			display: flex;
			justify-content: space-between;
            background: $color-yellow;
            align-items: center;
            padding: 15px;
            div.content-logo {
                max-height: 100%;
                img {
                    height: 50px;
                }
            }
			span {
				color: $color-white;
			}
		}
	}

	/* The Close Button */
	.close {
		color: #aaaaaa;
		float: right;
		font-size: 28px;
		font-weight: bold;
	}

	.close:hover,
	.close:focus {
		color: #000;
		text-decoration: none;
		cursor: pointer;
	}
}

section.section-header.historic.index {
    div.module-header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .caption {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            z-index: 99;
            text-align: center;
            transform: translateY(0%);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;

            h3 {
                min-width: 300px;
                text-align: center;
                font-weight: 700;
                position: absolute;
                color: $color-black;
                font-size: $font-size-60;
                padding: 10px;
                text-align: center;
                line-height: 1;
                text-shadow: 2px 2px 5px $color-white,
                             -2px -2px 5px $color-white,
                             2px -2px 5px $color-white,
                             -2px 2px 5px $color-white;
                //// Modif - code
                display: none;
                width: 35vw;
                min-height: 90px;
                padding: 15px 25px;
                align-items: center;
                justify-content: center;
                // background: rgba(0, 0, 0, 0.49);
                //border: 2px solid $color-white;
                margin-top: -10vw;
                transition: .5s;
                @media screen and (max-width: $size-max-2) {
                    width: 40vw;
                    margin-top: -8vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        width: 50vw;
                        margin-top: -5vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            width: 60vw;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-2) {
                                margin-top: -40vw;
                                transition: .5s;
                            }
                        }
                    }
                }
            }
        }
    }
}

section.section-002-index.extrat-param {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // background: $color-yellow;
    background: url(../images/location_machinerie_lourde_section_02_bg.jpg) no-repeat center center;
    background-size: cover;
    @media screen and (max-width: $size-max-m-1) {
        background-attachment: fixed;
        transition: .5s;
    }
    div.bar-yellow-top {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: url(../images/texture_strip_tape.jpg) no-repeat center center;
        background-size: cover;
    }
    div.bar-yellow-bottom {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: url(../images/texture_strip_tape.jpg) no-repeat center center;
        background-size: cover;
    }

    div.container-fluid {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 85px 5vw;
        transition: .5s;
        width: 100%;

        @media screen and (max-width: $size-max-2) {
            padding: 75px 4vw;
            transition: .5s;
            @media screen and (max-width: $size-max-3) {
                padding: 75px 2vw;
                transition: .5s;
            }
        }
    }

    div.header-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        h3.title-header {
            color: $color-black;
            font-size: 40px;
            font-weight: 500;
            border-top: 4px solid $color-black;
            border-bottom: 4px solid $color-black;
            padding: 25px 45px;
            text-align: center;
            transition: .5s;
            width: 30%;
            @media screen and (max-width: $size-max-2) {
                font-size: 35px;
                transition: .5s;
                @media screen and (max-width: $size-max-3) {
                    font-size: 28px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        width: 60%;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-4) {
                            width: 100%;
                            transition: .5s;
                        }
                    }
                }
            }
        }
    }

    div.body-section {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        margin-top: 65px;
        transition: .5s;
        @media screen and (max-width: $size-max-m-1) {
            flex-direction: column;
            margin-bottom: 75px;
            transition: .5s;
        }

        div.left-side-text {
            width: 40%;
            padding: 25px;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                width: 100%;
                padding: 15px;
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {
                    width: 100%;
                    padding: 5px;
                    transition: .5s;
                }
            }

            h3.title-mediatask {
                color: $color-black;
                font-size: 25px;
                font-weight: 400;
                margin-bottom: 45px;
                border-top: 2px solid $color-black;
                border-bottom: 2px solid $color-black;
                padding: 25px 0;
                @media screen and (max-width: $size-max-m-1) {
                    text-align: center;
                    font-size: 22px;
                    line-height: 24px;
                    transition: .5s;
                }
            }

            p {
                text-align: justify;
                color: $color-black;
                font-weight: 400;
                font-size: 20px;
                line-height: 25px;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    text-align: justify;
                    font-size: 18px;
                    line-height: 24px;
                    transition: .5s;
                }
            }
        }

        div.right-thumbs {
            width: 60%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding-top: 25px;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                width: 100%;
                flex-wrap: wrap;
                transition: .5s;
            }

            div.unique-thumb {
                margin: 10px;
                min-width: 100px;
            }
        }
    }
}

.centralised {
    align-items: center!important;
}

.pad-extrat {
    padding: 0 85px;
}

.pt-0 {
    padding-top: 0!important;
}

.pt-100 {
    padding-top: 5vw!important;
}

.bold-uper {
    text-transform: uppercase;
    color: $color-yellow;
}
/** Add this class to any anchor tag so it clear the fixed menu (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

.shadow-white {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
    }
}
/*
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

*/
.shadow {
	position: relative;
	&::after {
		content: '';
		z-index: 1;
		width: 100%;
		top: 100%;
		height: 40px;
		position: absolute;
		background: linear-gradient(to bottom , rgba(0 ,0 ,0 ,0.8), rgba(0 ,0 ,0 ,0) );
	}
}

section.section-003index-extrat-param {
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(../images/main_bg_large.jpg) no-repeat center center;
    background-size: cover;
    @media screen and (max-width: $size-max-m-1) {
        background-attachment: fixed;
        transition: .5s;
    }
    div.container-fluid {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 85px 0;
        width: 100%;
        div.header-section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;

            h3.title-header {
                color: $color-yellow;
                font-size: 40px;
                font-weight: 500;
                border-top: 4px solid $color-yellow;
                border-bottom: 4px solid $color-yellow;
                padding: 25px 45px;
                text-align: center;
                transition: .5s;
                width: 30%;
                @media screen and (max-width: $size-max-2) {
                    font-size: 35px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 28px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            width: 60%;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-4) {
                                width: 100%;
                                transition: .5s;
                            }
                        }
                    }
                }
            }
        }

        div.body-section {
            padding: 85px 2vw;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
            transition: .5s;
            width: 100%;
            @media screen and (max-width: $size-max-m-1) {
                flex-direction: column;
                transition: .5s;
            }

            div.unique-article {
                width: 40%;
                margin: 15px 4vw;
                margin-bottom: 75px;
                @media screen and (max-width: $size-max-m-1) {
                    width: 90%;
                    transition: .5s;
                }

                .header-article {
                    width: 100%;
                    display: flex;
                    align-content: space-between;
                    align-items: center;
                    min-height: 90px;

                    .content-logo {
                        width: 70%;
                    }

                    span {
                        width: 30%;
                        color: $color-white;
                        font-weight: 400;
                        font-size: 22px;
                        text-align: right;
                        @media screen and (max-width: $size-max-2) {
                            font-size: 20px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-3) {
                                font-size: 18px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-1) {
                                    font-size: 16px;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }

                div.content-title-article {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    border-bottom: 2px solid $color-white;
                    border-top: 2px solid $color-white;
                    padding: 45px 0;
                    margin: 15px 0;
                    @media screen and (max-width: $size-max-2) {
                        padding: 25px 0;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            padding: 25px 0;
                            transition: .5s;
                        }
                    }

                    h3.title-article {
                        color: $color-white;
                        font-size: 24px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-2) {
                            font-size: 22px;
                            line-height: 25px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-3) {
                                font-size: 18px;
                                line-height: 25px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-1) {
                                    text-align: center;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }

                div.footer-article {
                    width: 100%;
                    padding: 15px;

                    a.btn-border {
                        padding: 5px;
                        width: 300px;
                        height: 70px;
                        background: $color-yellow;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        transition: .5s;
                        &:hover {
                            background: $color-yellow-2;
                            transition: .5s;
                        }
                        @media screen and (max-width: $size-max-3) {
                            width: 250px;
                            height: 60px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                width: 250px;
                                height: 60px;
                                transition: .5s;
                            }
                        }

                        span {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-align: center;
                            color: $color-black;
                            border: 2px solid $color-black;
                            padding: 15px;
                            font-size: 22px;
                            transition: .5s;

                            @media screen and (max-width: $size-max-3) {
                                font-size: 18px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-1) {
                                    font-size: 16px;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

section.section-001-plate {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../images/main_bg_parallax.jpg');
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 95px 0;

    div.container-fluid {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: .5s;
        width: 80%;
        @media screen and (max-width: $size-max-2) {
            transition: .5s;
            width: 85%;
            @media screen and (max-width: $size-max-3) {
                transition: .5s;
                width: 95%;
                @media screen and (max-width: $size-max-m-1) {
                    transition: .5s;
                    width: 95%;
                }
            }
        }
    }

    h3.title-article {
        color: $color-yellow;
        font-size: 22px;
        text-align: left;
        width: 100%;
    }

    h3.min-title {
        color: $color-yellow;
        font-size: 35px;
        font-weight: 600;
        text-transform: none;
        text-align: left;
        width: 100%;
        @media screen and (max-width: $size-max-2) {
            transition: .5s;
            font-size: 30px;
            @media screen and (max-width: $size-max-3) {
                transition: .5s;
                font-size: 28px;
                @media screen and (max-width: $size-max-m-1) {
                    transition: .5s;
                    font-size: 25px;
                }
            }
        }
    }

    div.content-paragraph {
        margin-top: 45px;
        @media screen and (max-width: $size-max-m-1) {
            transition: .5s;
            margin-top: 25px;
        }
        p {
            color: $color-white;
            font-weight: 400;
            font-size: 22px;
            line-height: 30px;
            text-align: justify;
            transition: .5s;
            @media screen and (max-width: $size-max-2) {
                transition: .5s;
                font-size: 20px;
                @media screen and (max-width: $size-max-3) {
                    transition: .5s;
                    font-size: 18px;
                    @media screen and (max-width: $size-max-m-1) {
                        transition: .5s;
                        font-size: 16px;
                    }
                }
            }
        }
    }

    div.content-lists {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-top: 85px;
        transition: .5s;
        @media screen and (max-width: $size-max-m-2) {
            padding-top: 45px;
            flex-direction: column;
            transition: .5s;
        }

        ul.list-items {
            @media screen and (max-width: $size-max-m-2) {
                width: 100%;
                transition: .5s;
                margin-bottom: 35px;
            }
            h3.title-list {
                margin-left: -16px;
                color: $color-yellow;
                margin-bottom: 25px;
                font-size: 32px;
                text-transform: none;
                @media screen and (max-width: $size-max-2) {
                    transition: .5s;
                    font-size: 28px;
                    @media screen and (max-width: $size-max-3) {
                        transition: .5s;
                        font-size: 25px;
                        @media screen and (max-width: $size-max-m-1) {
                            transition: .5s;
                            font-size: 25px;
                        }
                    }
                }
            }

            li.item {
                color: $color-white;
                font-size: 22px;
                text-transform: none;
                font-weight: 500;
                line-height: 25px;
                margin-bottom: 20px;
                padding-left: 20px;
                letter-spacing: .2px;
                transition: .5s;
                @media screen and (max-width: $size-max-2) {
                    transition: .5s;
                    font-size: 20px;
                    margin-bottom: 15px;
                    @media screen and (max-width: $size-max-3) {
                        transition: .5s;
                        font-size: 18px;
                        //margin-bottom: 20px;
                        @media screen and (max-width: $size-max-m-1) {
                            transition: .5s;
                            font-size: 16px;
                            //margin-bottom: 15px;
                        }
                    }
                }
            }
        }
    }
}

section.section-002-page-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(../images/location_machinerie_lourde_section_02_bg.jpg) no-repeat center center;
    background-size: cover;
    padding: 85px 0;
    //padding-top: 0;
    width: 100%;
    @media screen and (max-width: $size-max-m-1) {
        background-attachment: fixed;
        transition: .5s;
    }
    div.container-fluid {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
        width: 100%;

        div.header-section-inner {
            margin-bottom: 5vw;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            transition: .5s;
            h3.first-title {
                color: $color-black;
                font-weight: 500;
                font-size: 40px;
                border-top: 3px solid $color-black;
                border-bottom: 3px solid $color-black;
                padding: 25px 45px;
                text-align: center;
                width: 32vw;
                min-width: 300px;
                @media screen and (max-width: $size-max-2) {
                    transition: .5s;
                    font-size: 35px;
                    width: 42vw;
                    @media screen and (max-width: $size-max-3) {
                        transition: .5s;
                        font-size: 30px;
                        width: 45vw;
                        @media screen and (max-width: $size-max-m-1) {
                            transition: .5s;
                            font-size: 25px;
                            width: 90%;
                            @media screen and (max-width: $size-max-m-4) {
                                transition: .5s;
                                font-size: 25px;
                            }
                        }
                    }
                }
            }
            h3.second-title {
                color: $color-black;
                font-weight: 500;
                font-size: 50px;
                border: 0 solid $color-black;
                padding: 25px 45px;
                text-align: center;
                width: 100%;
                min-width: 300px;
                margin-bottom: 45px;
                @media screen and (max-width: $size-max-2) {
                    transition: .5s;
                    font-size: 40px;
                    @media screen and (max-width: $size-max-3) {
                        transition: .5s;
                        font-size: 32px;
                        @media screen and (max-width: $size-max-m-1) {
                            transition: .5s;
                            font-size: 28px;
                        }
                    }
                }
            }
        }

        div.body-section-inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0;
            width: 100%;
        }

        ul.list-champs {
            width: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            list-style: none;
            margin-top: 2vw;
            margin-bottom: 5vw;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                padding: 0;
                transition: .5s;
                flex-wrap: wrap;
            }

            li {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 25%;
                min-width: 300px;
                transition: .5s;
                @media screen and (max-width: $size-max-2) {
                    transition: .5s;
                    min-width: 250px;
                    @media screen and (max-width: $size-max-3) {
                        transition: .5s;
                        min-width: 200px;
                        @media screen and (max-width: $size-max-m-1) {
                            transition: .5s;
                            min-width: 150px;
                            margin: 15px 0;
                            @media screen and (max-width: $size-max-m-2) {
                                transition: .5s;
                            }
                        }
                    }
                }

                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                }
                img {
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        transition: .5s;
                        width: 100px;
                    }
                }
                &:hover {
                    img {
                        transform: scale(1.3);
                        transition: .5s;
                    }
                }
            }

            h3.title-champs {
                color: $color-black;
                font-weight: 500;
                font-size: 25px;
                text-align: center;
                text-transform: uppercase;
                margin-top: 20px;
                @media screen and (max-width: $size-max-2) {
                    transition: .5s;
                    font-size: 22px;
                    @media screen and (max-width: $size-max-3) {
                        transition: .5s;
                        font-size: 18px;
                        @media screen and (max-width: $size-max-m-1) {
                            transition: .5s;
                            font-size: 16px;
                            @media screen and (max-width: $size-max-m-2) {
                                transition: .5s;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }

    }
}

section.section-001-infos-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('../images/main_bg_parallax.jpg');
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 75px 0;
    //padding-bottom: 0;

    div.container-fluid {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        div.header-section-infos-transport {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 0 solid $color-white;
            min-width: 300px;
            padding: 10px;
            transition: .5s;
            width: 100%;
            @media screen and (max-width: $size-max-2) {
                width: 100%;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    width: 100%;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-2) {
                        width: 85%;
                        transition: .5s;
                    }
                }
            }
            h3 {
                color: $color-yellow;
                text-transform: uppercase;
                font-size: 40px;
                text-align: center;
                border-top: 3px solid $color-yellow;
                border-bottom: 3px solid $color-yellow;
                width: 75%;
                transition: .5s;
                padding: 15px 15px;
                font-weight: 500;
                line-height: 50px;
                @media screen and (max-width: $size-max-3) {
                    font-size: 30px;
                    line-height: 40px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-4) {
                        font-size: 25px;
                        line-height: 30px;
                        transition: .5s;
                        width: 90%;
                        @media screen and (max-width: $size-max-m-1) {
                            font-size: 22px;
                            line-height: 28px;
                            transition: .5s;
                            width: 95%;
                            @media screen and (max-width: $size-max-m-2) {
                                font-size: 20px;
                                line-height: 25px;
                                transition: .5s;
                            }
                        }
                    }
                }
            }
        }

        div.first-block-infos-transport {
            width: 70%;
            transition: .5s;
            justify-content: space-evenly;
            align-items: flex-start;
            display: flex;
            @media screen and (max-width: $size-max-m-1) {
                width: 80%;
                align-items: center;
                flex-direction: column;
                transition: .5s;
                @media screen and (max-width: $size-max-m-4) {
                    width: 95%;
                    transition: .5s;
                }
            }
            h3.title-header-block {
                width: 100%;
                text-align: center;
                color: $color-yellow;
                font-size: 40px;
                font-weight: 500;
                transition: .5s;
                @media screen and (max-width: $size-max-2) {
                    font-size: 35px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 30px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-4) {
                            font-size: 28px;
                            transition: .5s;
                        }
                    }
                }
            }

            ul.list-elt {
                margin-top: 65px;
                margin-bottom: 65px;
                justify-content: flex-start;
                flex-direction: column;
                display: flex;
                padding: 0;
                @media screen and (max-width: $size-max-m-1) {
                    padding: 15px;
                    transition: .5s;
                }
                &:nth-child(1) {
                    width: 70%;
                    align-items: flex-start;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        align-items: center;
                        margin-bottom: 0;
                        padding-bottom: 0;
                        transition: .5s;
                        width: 100%;
                    }
                    li.item {
                        text-transform: none;
                        color: $color-white;
                        line-height: 35px;
                        font-weight: 400;
                        margin-bottom: 12px;
                        transition: .5s;
                        font-size: 25px;
                        @media screen and (max-width: $size-max-2) {
                            font-size:  22px;
                            line-height: 25px;
                            margin-bottom: 10px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-3) {
                                font-size:  20px;
                                line-height: 25px;
                                margin-bottom: 10px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-1) {
                                    text-align: center;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
                &:nth-child(2) {
                    width: 30%;
                    align-items: flex-start;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        width: 40%;
                        transition: .5s;
                    }
                    @media screen and (max-width: $size-max-m-1) {
                        align-items: center;
                        margin-top: 0;
                        padding-top: 0;
                        transition: .5s;
                        width: 100%;
                    }
                    li.item {
                        text-align: left;
                        text-transform: none;
                        color: $color-white;
                        font-size: 25px;
                        line-height: 35px;
                        font-weight: 400;
                        margin-bottom: 12px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-2) {
                            font-size:  22px;
                            line-height: 25px;
                            margin-bottom: 10px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-3) {
                                font-size:  20px;
                                line-height: 25px;
                                margin-bottom: 10px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-1) {
                                    text-align: center;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }

                li.item {
                    span {
                        font-size: 42px;
                        margin: 0 7px;
                        margin-left: 0;
                        @media screen and (max-width: $size-max-2) {
                            font-size: 35px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-3) {
                                font-size: 30px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-4) {
                                    font-size: 25px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-1) {
                                        font-size: 22px;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-2) {
                                            font-size: 20px;
                                            transition: .5s;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    span.small {
                        font-size: 30px;
                        margin: 0 7px;
                        margin-left: 0;
                        @media screen and (max-width: $size-max-2) {
                            font-size: 25px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-3) {
                                font-size: 22px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-4) {
                                    font-size: 20px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-1) {
                                        font-size: 18px;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-2) {
                                            font-size: 16px;
                                            transition: .5s;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.second-list {
                    width: 50%;
                    align-items: center;
                    transition: .5s;
                    @media screen and (max-width: $size-max-1) {
                        width: 60%;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            width: 70%;
                            transition: .5s;
                        }
                    }
                    li.item {
                        width: auto;
                        margin: 10px 5px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-2) {
                            transition: .5s;
                            span {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        div.first-block-infos-transport-2 {
            width: 70%;
            transition: .5s;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            display: flex;
            @media screen and (max-width: $size-max-4) {
                width: 100%;
                transition: .5s;
            }
            h3.title-header-block {
                border: 0;
            }
            ul.list-elt {
                width: 100%;
                margin-top: 65px;
                margin-bottom: 65px;
                justify-content: space-around;
                display: flex;
                padding: 0;
                @media screen and (max-width: $size-max-m-1) {
                    margin-top: 45px;
                    margin-bottom: 5px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-2) {
                       flex-direction: column;
                       justify-content: center;
                       align-items: center;
                        transition: .5s;
                    }
                }
                li.item {
                    text-align: left;
                    text-transform: none;
                    color: $color-white;
                    font-size: 32px;
                    line-height: 35px;
                    font-weight: 400;
                    margin-bottom: 12px;
                    padding-left: 3.5%;
                    transition: .5s;
                    margin: 0 15px;
                    @media screen and (max-width: $size-max-1) {
                        padding-left: 3.0%;
                        transition: .5s;
                        @media screen and (max-width: $size-max-2) {
                            font-size:  30px;
                            line-height: 25px;
                            margin-bottom: 10px;
                            padding-left: 2%;
                            transition: .5s;
                            @media screen and (max-width: $size-max-3) {
                                font-size:  25px;
                                line-height: 25px;
                                margin-bottom: 10px;
                                padding-left: 2.0%;
                                transition: .5s;
                                @media screen and (max-width: $size-max-4) {
                                    padding-left: 3%;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-1) {
                                        text-align: center;
                                        padding-left: 15px;
                                        transition: .5s;
                                    }
                                }
                            }
                        }
                    }
                    &:nth-child(1) {
                        list-style-type: none;
                        @media screen and (max-width: $size-max-m-2) {
                            list-style-type: disc;
                        }
                    }
                }
            }
        }

        h3 {
            font-size: 35px;
            text-align: center;
            color: $color-white;
            text-transform: none;
            padding: 15px 15px;
            line-height: 50px;
            font-weight: 500;
            transition: .5s;
            width: 100%;
            @media screen and (max-width: $size-max-3) {
                font-size: 30px;
                line-height: 40px;
                transition: .5s;
                @media screen and (max-width: $size-max-4) {
                    font-size: 25px;
                    line-height: 30px;
                    transition: .5s;
                    width: 90%;
                    @media screen and (max-width: $size-max-m-1) {
                        font-size: 22px;
                        line-height: 28px;
                        transition: .5s;
                        width: 95%;
                        @media screen and (max-width: $size-max-m-2) {
                            font-size: 20px;
                            line-height: 25px;
                            transition: .5s;
                        }
                    }
                }
            }
        }

        h3.title-header-block {
            font-size: 40px;
            text-align: center;
            color: $color-yellow;
            text-transform: uppercase;
            border-top: 3px solid $color-yellow;
            border-bottom: 3px solid $color-yellow;
            padding: 15px 15px;
            line-height: 50px;
            font-weight: 500;
            transition: .5s;
            width: 100%;
            @media screen and (max-width: $size-max-3) {
                font-size: 30px;
                line-height: 40px;
                transition: .5s;
                @media screen and (max-width: $size-max-4) {
                    font-size: 25px;
                    line-height: 30px;
                    transition: .5s;
                    width: 90%;
                    @media screen and (max-width: $size-max-m-1) {
                        font-size: 22px;
                        line-height: 28px;
                        transition: .5s;
                        width: 95%;
                        @media screen and (max-width: $size-max-m-2) {
                            font-size: 20px;
                            line-height: 25px;
                            transition: .5s;
                        }
                    }
                }
            }
        }

        h4 {
            color: $color-yellow;
            text-transform: uppercase;
            font-size: 40px;
            font-weight: 500;
            text-align: center;
            width: 100%;
            margin-top: 0;
            padding:  15px;
            transition: .5s;
            @media screen and (max-width: $size-max-3) {
                font-size: 30px;
                transition: .5s;
                @media screen and (max-width: $size-max-4) {
                    font-size: 25px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        font-size: 22px;
                        line-height: 28px;
                        transition: .5s;
                    }
                }
            }
        }

        p {
            width: 65%;
            color: $color-white;
            font-size: 22px;
            font-weight: 400;
            line-height: 30px;
            text-align: center;
            transition: .5s;
            @media screen and (max-width: $size-max-3) {
                width: 75%;
                font-size: 20px;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    width: 85%;
                    font-size: 18px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-2) {
                        width: 95%;
                        transition: .5s;
                        padding: 15px;
                    }
                }
            }
        }

        div.content-btn-border {
            width: 300px;
            height: 65px;
            display: flex;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: $size-max-m-1) {
                width: 250px;
                height: 65px;
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {

                }
            }

            a.btn-border {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px;
                background: $color-yellow;
                text-align: center;
                text-transform: uppercase;                color: $color-white;
                transition: .5s;
                &:hover {
                    background: $color-yellow-2;
                    transition: .5s;
                }

                span {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 18px;
                    color: $color-white;
                    text-align: center;
                    border: 2px solid $color-white;
                    @media screen and (max-width: $size-max-m-1) {
                        font-size: 16px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-2) {
                            font-size: 15px;
                            transition: .5s;
                        }
                    }
                }
            }
        }

        div.top-content-infs {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 45%;
            transition: .5s;
            @media screen and (max-width: $size-max-3) {
                width: 55%;
                transition: .5s;
                @media screen and (max-width: $size-max-4) {
                    width: 65%;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        width: 80%;
                        transition: .5s;
                        @media screen and (max-width: $size-max-2) {
                            width: 95%;
                            transition: .5s;
                        }
                    }
                }
            }
            &:nth-child(2) {
                border-top: 2px solid $color-yellow;
                margin-top: 35px;
                padding-top: 25px;
                // padding-bottom: 65px;
            }

            div.content-img-post-header {
                margin: 25px 0;
            }

            h3 {
                color: $color-yellow;
                font-size: 37px;
                font-weight: 500;
                text-align: center;
                width: 100%;
                margin-bottom: 15px;
                text-transform: uppercase;
                @media screen and (max-width: $size-max-3) {
                    font-size: 30px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-4) {
                        font-size: 25px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            transition: .5s;
                            @media screen and (max-width: $size-max-2) {
                                transition: .5s;
                            }
                        }
                    }
                }
            }

            p {
                width: 100%;
                color: $color-white;
                font-size: 25px;
                font-weight: 400;
                text-align: center;
                line-height: 35px;
                margin-top: 15px;
                transition: .5s;
                @media screen and (max-width: $size-max-2) {
                    font-size: 22px;
                    line-height: 30px;
                    transition: .5s;
                }
                @media screen and (max-width: $size-max-3) {
                    font-size: 20px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-4) {
                        font-size: 18px;
                        transition: .5s;
                    }
                }
            }
        }

        div.top-content-infs.trans-specialiser {
            transition: .5s;
            p {
                width: 100%;
                color: $color-white;
                font-size: 22px;
                font-weight: 400;
                text-align: center;
                line-height: 35px;
                margin-top: 15px;
                transition: .5s;
                @media screen and (max-width: $size-max-2) {
                    font-size: 20px;
                    line-height: 30px;
                    transition: .5s;
                }
                @media screen and (max-width: $size-max-3) {
                    font-size: 18px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-4) {
                        font-size: 18px;
                        transition: .5s;
                        padding: 0;
                    }
                }
            }
        }
    }

    div.content-list-mix {
        display: flex;
        align-content: center;
        align-items: flex-start;
        width: 75%;
        transition: .5s;
        @media screen and (max-width: $size-max-2) {
            width: 85%;
            transition: .5s;
            @media screen and (max-width: $size-max-3) {
                width: 90%;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    width: 90%;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-3) {
                        width: 100%;
                        transition: .5s;
                        flex-direction: column;
                    }
                }
            }
        }

        div.first-elt {
            width: 35%;
            display: flex;
            align-content: center;
            transition: .5s;
            @media screen and (max-width: $size-max-m-3) {
                width: 100%;
                transition: .5s;
                padding: 15px;
                justify-content: center;
            }
        }

        div.millde-one {
            width: 35%;
            display: flex;
            align-content: center;
            @media screen and (max-width: $size-max-m-3) {
                width: 100%;
                transition: .5s;
                padding: 15px;
                justify-content: center;
            }
        }

        div.right-one {
            width: 35%;
            display: flex;
            align-content: center;
            @media screen and (max-width: $size-max-m-3) {
                width: 100%;
                transition: .5s;
                padding: 15px;
                justify-content: center;
            }
        }

        h3 {
            color: $color-yellow;
            font-size: 32px;
            line-height: 42px;
            font-weight: 500;
            text-align: left;
            text-transform: uppercase;
            transition: .5s;
            @media screen and (max-width: $size-max-2) {
                font-size: 28px;
                line-height: 28px;
                transition: .5s;
                @media screen and (max-width: $size-max-3) {
                    font-size: 22px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        font-size: 20px;
                        line-height: 28px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-3) {
                            width: 100%;
                            text-align: center;
                            transition: .5s;
                        }
                    }
                }
            }
        }

        ul {
            @media screen and (max-width: $size-max-m-3) {
                width: 100%;
                text-align: center;
                padding: 0;
                transition: .5s;
                list-style: none;
                margin-bottom: 35px;
            }
            h3 {
                color: $color-yellow;
                font-size: 25px;
                font-weight: 500;
                text-align: left;
                text-transform: uppercase;
                margin-left: -25px;
                margin-bottom: 35px;
                @media screen and (max-width: $size-max-2) {
                    font-size: 24px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 20px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            font-size: 18px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-3) {
                                width: 100%;
                                text-align: center;
                                transition: .5s;
                                margin: 0;
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
            }
            li {
                color: $color-white;
                font-weight: 400;
                font-size: 24px;
                text-transform: none;
                line-height: 22px;
                margin-bottom: 15px;
                @media screen and (max-width: $size-max-2) {
                    font-size: 22px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 20px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            font-size: 18px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-2) {
                                font-size: 16px;
                                margin-bottom: 5px;
                                transition: .5s;
                            }
                        }
                    }
                }
            }
        }
    }

    div.unique-center-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 0 solid $color-yellow;
        transition: .5s;
        padding: 35px;
        @media screen and (max-width: $size-max-4) {
            padding: 15px;
            padding-top: 25px;
            transition: .5s;
        }

        h3.title-box {
            color: $color-yellow;
            font-size: 42px;
            font-weight: 500;
            margin-bottom: 35px;
            text-align: center;
            transition: .5s;
            @media screen and (max-width: $size-max-2) {
                font-size: 38px;
                margin-bottom: 30px;
                transition: .5s;
                @media screen and (max-width: $size-max-3) {
                    font-size: 35px;
                    margin-bottom: 25px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-4) {
                        font-size: 30px;
                        margin-bottom: 10px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            font-size: 25px;
                            margin-bottom: 10px;
                            transition: .5s;
                        }
                    }
                }
            }
        }

        p {
            color: $color-white;
            font-weight: 400;
            font-size: 25px;
            transition: .5s;
            @media screen and (max-width: $size-max-2) {
                font-size: 22px;
                transition: .5s;
                @media screen and (max-width: $size-max-3) {
                    font-size: 20px;
                    transition: .5s;
                }
            }
        }
    }
}

section.section-mosaic-static {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url(../images/main_bg.jpg) no-repeat center center;
    background-attachment: fixed;
    background-size: cover;
    div.container-fluid {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        div.content-top-photos {
            display: flex;
            justify-content: space-around;
            align-items: center;
            transition: .5s;
            @media screen and (max-width: $size-max-m-2) {
                flex-direction: column;
                transition: .5s;
                padding: 15px;
            }
            div.conent-left-img,
            div.content-right-img {
                width: 50%;
                padding: 5px;
                padding: 5px;
                display: flex;
                background: $color-white;
                justify-content: center;
                align-items: center;
                transition: .5s;
                margin: 15px;
                margin-bottom: 5px;
                @media screen and (max-width: $size-max-m-2) {
                    width: 100%;
                    transition: .5s;
                }
            }
            div.conent-left-img {
                margin-right: 8.5px;
            }
            div.content-right-img {
                margin-left: 8.5px;
            }
        }

        div.content-bottom-photos {
            display: flex;
            align-items: center;
            justify-content: center;
            background: $color-white;
            background: url(../images/main_bg.jpg) no-repeat center center;
            background-attachment: fixed;
            background-size: cover;
            width: 100%;
            @media screen and (max-width: $size-max-m-4) {
                width: 92%;
                flex-direction: column;
                transition: .5s;
            }
            div.content-unic-photo {
                transition: .5s;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;
                margin: 15px;
                border: 5px solid $color-white;
                img {
                    transition: .5s;
                }
                @media screen and (max-width: $size-max-m-2) {
                    width: 100%;
                    margin: 15px;
                    transition: .5s;
                }
                &:nth-child(2) {
                    margin: 15px 7.5px;
                    transition: .5s;
                }
            }
        }
    }
}

section.section-middle-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url(../images/main_bg_medium.jpg) no-repeat center center;
    background-attachment: fixed;
    background-size: cover;
    min-height: 250px;
    padding: 75px 0;
    transition: .5s;

    div.container-fluid {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        div.header-section-text-middle {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 15px;
            h3.title-header {
                text-align: center;
                width: 100%;
                color: $color-yellow;
                font-size: 40px;
                font-weight: 500;
                transition: .5s;
                @media screen and (max-width: $size-max-2) {
                    font-size: 35px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 32px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-4) {
                            font-size: 28px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                font-size: 25px;
                                transition: .5s;
                            }
                        }
                    }
                }
            }
        }

        div.body-section {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            div.content-paragraphs {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                p {
                    color: $color-white;
                    font-weight: 400;
                    font-size: 22px;
                }
            }
        }
    }
}

section.section-images-last {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $color-white;
    background: url(../images/main_bg_medium.jpg) no-repeat center center;
    background-attachment: fixed;
    background-size: cover;

    div.container-fluid {
        padding: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        div.content-last-img {
            display: flex;
            justify-content: center;
            align-items: center;
            transition: .5s;
            @media screen and (max-width: $size-max-m-2) {
                flex-direction: column;
                margin: 15px;
                transition: .5s;
            }
            div.conent-unique-photo-last {
                width: 50%;
                padding: 5px;
                transition: .5s;
                background: $color-white;
                margin: 15px;
                &:nth-child(1) {
                    margin-right: 8.5px;
                    @media screen and (max-width: $size-max-m-2) {
                        margin: 15px;
                        transition: .5s;
                    }
                }
                &:nth-child(2) {
                    margin-left: 8.5px;
                    @media screen and (max-width: $size-max-m-2) {
                        margin: 15px;
                        transition: .5s;
                    }
                }
                @media screen and (max-width: $size-max-m-2) {
                    width: 100%;
                    transition: .5s;
                }
            }
        }
    }
}

section.section-products {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 75px 0;
    transition: .5s;
    @media screen and (max-width: $size-max-3) {
        padding: 55px 0;
        transition: .5s;
        @media screen and (max-width: $size-max-m-1) {
            padding: 0 0;
            transition: .5s;
        }
    }

    div.container-fluid {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        div.header-section {
            width: 100%;
            height: 250px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 85px;
            @media screen and (max-width: $size-max-m-1) {
                margin: 0;
                transition: .5s;
            }

            h3.title-header {
                width: 45vw;
                min-width: 300px;
                text-align: center;
                border-top: 8px solid $color-yellow;
                border-bottom: 8px solid $color-yellow;
                padding: 45px 0;
                color: $color-yellow;
                font-size: 40px;
                font-weight: 500;
                @media screen and (max-width: $size-max-2) {
                    font-size: 35px;
                    padding: 35px 0;
                    transition: .5s;
                    border-top: 6px solid $color-yellow;
                    border-bottom: 6px solid $color-yellow;
                    @media screen and (max-width: $size-max-3) {
                        width: 55vw;
                        font-size: 30px;
                        padding: 25px 0;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            font-size: 25px;
                            transition: .5s;
                        }
                    }
                }
            }
        }

        div.body-section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            transition: .5s;
            width: 100%;

            div.content-uniques-prods {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                transition: .5s;
                width: 100%;
            }

            div.unique-product {
                width: 27%;
                min-width: 250px;
                min-height: 240px;
                //height: 300px;
                //overflow: hidden;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 20px;
                margin-bottom: 35px;
                transition: .5s;
                @media screen and (max-width: $size-max-3) {
                    margin: 10px;
                    margin-bottom: 15px;
                    transition: .5s;
                }
                @media screen and (max-width: $size-max-m-3) {
                    width: 98%;
                    margin: 0;
                    margin-bottom: 25px;
                    transition: .5s;
                }
                &:hover {
                    a {
                        div.content-photo {
                            -webkit-box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.99);
                            -moz-box-shadow:    0px 1px 16px 0px rgba(0, 0, 0, 0.99);
                            box-shadow:         0px 1px 16px 0px rgba(0, 0, 0, 0.99);
                            transition: .5s;
                            img {
                                -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                                filter: grayscale(100%);
                                transition: .5s;
                            }
                        }
                    }
                }
                a {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    transition: .5s;

                    div.content-photo {
                        display: flex;
                        overflow: hidden;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        max-height: 285px;
                        max-width: 100%;
                        // -webkit-box-shadow: 0px 1px 16px 0px rgba(50, 50, 50, 0.65);
                        // -moz-box-shadow:    0px 1px 16px 0px rgba(50, 50, 50, 0.65);
                        // box-shadow:         0px 1px 16px 0px rgba(50, 50, 50, 0.65);
                        transition: .5s;

                        div.content-inner-imgs {
                            display: none;
                            justify-content: center;
                            align-items: center;
                            margin-top: 5px;
                            margin-bottom: 5px;

                            img {
                                width: 30%;
                                transition: .5s;
                            }
                        }
                    }

                    h3.title-product {
                        color: $color-2;
                        font-weight: 500;
                        text-align: left;
                        text-transform: none;
                        width: 100%;
                        margin-top: 20px;
                        font-size: 25px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-2) {
                            font-size: 22px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-3) {
                                font-size: 20px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-1) {
                                    font-size: 18px;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

section.section-003-inner-transport {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 85px 0;
    width: 100%;
    transition: .5s;
    @media screen and (max-width: $size-max-m-3) {
        padding: 85px 15px;
        transition: .5s;
    }

    div.header-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        h3 {
            color: $color-black;
            font-size: 30px;
            font-weight: 500;
            text-align: center;
            padding:20px 30px;
            background: $color-yellow;
            transition: .5s;
            @media screen and (max-width: $size-max-2) {
                font-size: 28px;
                transition: .5s;
                @media screen and (max-width: $size-max-3) {
                    font-size: 25px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-4) {
                        font-size: 22px;
                        transition: .5s;
                    }
                }
            }
        }
    }

    div.body-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        transition: .5s;
        margin-top: 85px;
        padding: 0 15px;


        div.content-terms {
            max-height: 450px;
            overflow: auto;
            border-top: 1px solid $color-grey-light-2;
            border-bottom: 1px solid $color-grey-light-2;
            padding: 45px 0;
        }

        p {
            color: $color-black;
            font-size: 17px;
            line-height: 22px;
            font-weight: 400;
            text-align: left;
            width: 100%;
            transition: .5s;
            margin-bottom: 35px;
            @media screen and (max-width: $size-max-2) {
                //width: 75%;
                font-size: 20px;
                line-height: 28px;
                transition: .5s;
                @media screen and (max-width: $size-max-4) {
                    //width: 85%;
                    font-size: 17px;
                    line-height: 22px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        //width: 95%;
                        font-size: 14px;
                        line-height: 18px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-2) {
                            text-align: center;
                            transition: .5s;
                        }
                    }
                }
            }
        }
    }
}


///////// GALLETY STYLE - PopUp
div.produ-fsb-popup {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 1px;
    transition: .5s;
    @media screen and (max-width: $size-max-4) {
        flex-direction: column;
        justify-content: center;
        transition: .5s;
    }

    div.content-imgs-prod {
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        //border-right: 1px solid $color-grey-light-2;
        padding-right: 5px;
        //background: $color-grey;
        @media screen and (max-width: $size-max-4) {
            width: 100%;
            border-right: 0 solid $color-grey-light-2;
            border-bottom: 1px solid $color-grey-light-2;
            padding-bottom: 45px;
            margin-bottom: 25px;
            transition: .5s;
            @media screen and (max-width: $size-max-m-3) {
                margin-bottom: 5px;
                transition: .5s;
            }
        }

        div.content-principal-picture {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            width: 98%;
            // height: 35vw;
            // min-height: 550px;
            transition: .5s;
            @media screen and (max-width: $size-max-4) {
                //min-height: 650px;
                height: auto;
                transition: .5s;
                @media screen and (max-width: $size-max-m-3) {
                    //min-height: 450px;
                    transition: .5s;
                }
            }
        }

        img {
            cursor: pointer;
        }

        div.content-small-imgs {
            display: flex;
            justify-content: center;
            align-items: center;
            //padding-top: 15px;
            width: 100%;
            overflow: hidden;

            div.content-thumb-small {
                width: 33%;
                max-height: 100px;
                overflow: hidden;
                transition: .5s;
                @media screen and (max-width: $size-max-m-3) {
                    max-height: 60px;
                    transition: .5s;
                }
                img {
                    width: 100%;
                    margin: 5px;
                    :nth-child(1) {
                        margin-left: 0;
                    }
                    :nth-child(3) {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    div.content-descritpion {
      width: 60%;
      padding: 15px;
      @media screen and (max-width: $size-max-4) {
        width: 100%;
        transition: .5s;
    }

      div.header-descriptions {
          margin-bottom: 20px;
          h3.title-prod {
                color: $color-2;
                font-size: 25px;
                font-weight: 500;
                text-transform: none;
                width: 100%;
                text-align: left;
                transition: .5s;
                @media screen and (max-width: $size-max-2) {
                    font-size: 22px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 18px;
                        transition: .5s;
                    }
                }
          }
      }

      div.body-dscriptions {
            img {
                cursor: pointer;
            }
            div.tableau-donnees {
                margin: 45px 0;
                h3 {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: $color-grey-dark;
                    text-transform: none;
                    border-bottom: 1px solid $color-grey-light-2;
                    line-height: 25px;
                    width: 100%;
                    span {
                        &:nth-child(1) {
                            width: 50%;
                            font-weight: 400;
                            font-size: 16px;
                        }
                        &:nth-child(2) {
                            width: 50%;
                            font-weight: 400;
                            font-size: 16px;
                            text-align: right;
                            padding-right: 5px;
                        }
                    }
                }
            }

            div.content-paragrph-description {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                h3 {
                    color: $color-2;
                    font-size: 20px;
                    font-weight: 500;
                    text-transform: uppercase;
                    width: 100%;
                    text-align: left;
                }

                p {
                    color: $color-black;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 25px;
                    text-transform: none;
                    width: 100%;
                    text-align: left;
                }

                h1, h2, h3, h4 {
                    width: 100%;
                }

                ul {
                    width: 100%;
                    li {
                        width: 100%;
                        line-height: 25px;
                        color: $color-black;
                    }

                    p {
                        width: 100%;
                        color: $color-black;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 30px;
                    }
                }
            }

            div.footer-product {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                padding-top: 15px;
                border-top: 1px solid $color-grey-light-2;
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {
                    flex-wrap: wrap;
                    transition: .5s;
                }

                a.btn-border {
                    width: 300px;
                    height: 45px;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: space-around;
                    background: $color-2;
                    color: $color-white;
                    padding: 5px;
                    margin: 10px;
                    text-transform: uppercase;
                    transition: .5s;
                    border-radius: 2px;
                    &:hover {
                        background: $color-3;
                        transition: .5s;
                    }

                    span {
                        width: 100%;
                        height: 100%;
                        font-size: 16px;
                        font-weight: 400;
                        text-align: center;
                    }

                    img {
                        width: 35px;
                    }
                }
            }
        }
    }
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 70%;
  background: #FFF;
  cursor: auto;
  padding: 15px;
  transition: .5s;
  @media screen and (max-width: 1465px) {
      width: 98%;
      transition: .5s;
  }
}

div.caroussel-logos {
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    background: rgba(204, 204, 204, 0);
    margin: 25px 0;
    transition: .5s;

    .slick-slide img {
        margin: auto;
    }

    @media screen and (max-width: $size-max-m-1) {
        width: 100%;
        height: 150px;
        transition: .5s;
    }

    img {
        @media screen and (max-width: $size-max-m-4) {
            //min-height: 45px;
            //min-width: 115px;
            transition: .5s;
        }
    }

    h3 {
        color: $color-white;
        padding: 25px;
    }
}

.WYSWYG {
    ul {
        list-style: none;
        padding: 0;
        p, li, span, a, label {
            text-transform: none;
        }

        p {
            color: $color-white;
            font-weight: 400;
            font-size: 20px;
            line-height: 25px;
            transition: .5s;
            text-align: justify;
            @media screen and (max-width: $size-max-m-1) {
                font-size: 18px;
                line-height: 24px;
                transition: .5s;
            }
        }

        li {
            color: $color-white;
            font-weight: 400;
            font-size: 20px;
            line-height: 25px;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                font-size: 18px;
                line-height: 24px;
                transition: .5s;
            }
        }

        a {
            color: $color-white;
            font-weight: 400;
            font-size: 20px;
            line-height: 25px;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                font-size: 18px;
                line-height: 24px;
                transition: .5s;
            }
        }

        label {
            color: $color-white;
            font-weight: 400;
            font-size: 20px;
            line-height: 25px;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                font-size: 18px;
                line-height: 24px;
                transition: .5s;
            }
        }

        span {
            color: $color-white;
            font-weight: 400;
            font-size: 20px;
            line-height: 25px;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                font-size: 18px;
                line-height: 24px;
                transition: .5s;
            }
        }
    }

    p, li, span, a, label {
        text-transform: none;
    }

    p {
        color: $color-white;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        transition: .5s;
        text-align: justify;
        @media screen and (max-width: $size-max-m-1) {
            font-size: 18px;
            line-height: 24px;
            transition: .5s;
            text-align: justify;
        }
    }

    li {
        color: $color-white;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        transition: .5s;
        @media screen and (max-width: $size-max-m-1) {
            font-size: 18px;
            line-height: 24px;
            transition: .5s;
            text-align: justify;
        }
    }

    a {
        color: $color-white;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        transition: .5s;
        @media screen and (max-width: $size-max-m-1) {
            font-size: 18px;
            line-height: 24px;
            transition: .5s;
            text-align: justify;
        }
    }

    label {
        color: $color-white;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        transition: .5s;
        @media screen and (max-width: $size-max-m-1) {
            font-size: 18px;
            line-height: 24px;
            transition: .5s;
            text-align: justify;
        }
    }

    span {
        color: $color-white;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        transition: .5s;
        @media screen and (max-width: $size-max-m-1) {
            font-size: 18px;
            line-height: 24px;
            transition: .5s;
            text-align: justify;
        }
    }
}

////////////// OFFRE D'EMOLOI
section.section-emplois-offerts {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 115px 5vw;
    padding-bottom: 0;
    width: 100%;

    div.container-fluid {
        display: flex;
        align-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
        transition: .5s;
        @media screen and (max-width: $size-max-m-1) {
            flex-direction: column;
            padding: 0;
            transition: .5s;
        }

        .morecontent span {
            display: none;
        }
        .morelink {
            display: block;
            font-weight: 600;
            color: #c10;
            font-size: 15px;
            transition: .5s;
            &:hover {
                color: rgb(129, 15, 4);
                transition: .5s;
            }
        }

        .view {
            max-width: 100%;
            margin: auto;
            height: auto;
            // border: 10px solid black;
            // border-top: 30px solid black;
            // border-bottom: 30px solid black;
            // border-radius: 15px; padding: 10px;
            position: relative;
          }

        div.unique-offer {
            display: flex;
            flex-direction: column;
            align-content: flex-start;
            align-items: flex-start;
            margin-bottom: 95px;
            width: 50%;
            padding: 0 5vw;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                padding: 0;
                width: 100%;
                transition: .5s;
            }

            div.header-offert {
                margin-bottom: 15px;
                width: 100%;
                h3.title-offert {
                    color: $color-black;
                    font-size: 25px;
                    font-weight: 500;
                    line-height: 30px;
                    width: 100%;
                    text-transform: none;
                    @media screen and (max-width: $size-max-m-1) {
                        transition: .5s;
                        text-align: center;
                        width: 100%;
                    }
                }
                h3.date-apply-offert {
                    color: $color-black;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 30px;
                    width: 100%;
                    text-transform: none;
                    span {
                        font-size: 16px;
                    }
                    @media screen and (max-width: $size-max-m-1) {
                        transition: .5s;
                        text-align: center;
                        width: 100%;
                    }
                }

                h3.succursal-name-offert {
                    color: $color-black;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 30px;
                    width: 100%;
                    text-transform: none;
                    @media screen and (max-width: $size-max-m-1) {
                        transition: .5s;
                        text-align: center;
                        width: 100%;
                    }
                }
            }

            div.content-description {
                //display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                div.content-read-more {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        justify-content: center;
                        transition: .5s;
                    }
                }
            }

            div.description-offert {
                @media screen and (max-width: $size-max-m-1) {
                    width: 100%;
                    transition: .5s;
                    h2, h3 {
                        text-align: center;
                        transition: .5s;
                    }
                }
                p {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 25px;
                    margin-bottom: 15px;
                    @media screen and (max-width: $size-max-2) {
                        font-size: 20px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            font-size: 18px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-4) {
                                //font-size: 24px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-1) {
                                    font-size: 16px;
                                    transition: .5s;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
                a {
                    color: $color-2;
                    font-size: 18px;
                    transition: .5s;
                    &:hover {
                        transition: .5s;
                        color: $color-3;
                    }
                }

                ul {
                    //padding: 0;
                    li {
                        text-transform: none;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 25px;
                        margin-bottom: 5px;
                    }
                }
            }

            div.foooter-offert {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                margin-top: 45px;
                width: 100%;

                h3.date-end-apply {
                    color: $color-black;
                    font-size: 17px;
                    font-weight: 400;
                    line-height: 25px;
                    text-transform: none;
                    @media screen and (max-width: $size-max-m-1) {
                        transition: .5s;
                        text-align: center;
                        width: 100%;
                    }
                }

                div.content-button {
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    border-top: 1px solid $color-grey-light-2;
                    padding-top: 45px;

                    @media screen and (max-width: $size-max-m-1) {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 15px;
                        margin-top: 45px;
                        transition: .5s;
                    }

                    a.btn-apply {
                        width: 280px;
                        height: 60px;
                        font-size: 24px;
                        padding: 17px 40px;
                        color: $color-white;
                        background: $color-yellow;
                        text-align: center;
                        transition: .5s;
                        &.empty {
                            background: $color-grey-light-2;
                            transition: .5s;
                            &:hover {
                                background: $color-grey-light-2;
                                transition: .5s;
                            }
                        }
                        &:hover {
                            background: $color-yellow-2;
                            transition: .5s;
                        }
                        @media screen and (max-width: $size-max-m-1) {
                            height: 60px;
                            transition: .5s;
                        }
                    }
                }
            }
        }
    }
}

section.section-003-logo-carousel {
    border-top: 1px solid $color-grey-light-2;
}

div.content-form-02-extrat {
    section.input-section  {
        .form-group {
            label {
                //display: none;
                color: $color-black;
                font-weight: 500;
                font-size: 15px;
                margin-bottom: 2px;
            }

            .form-control {
                border-radius: 2px;
                height: 45px;
                font-size: 16px;
                background: $color-white;
                color: $color-black;
                border: 0 solid $color-black;
            }
        }
    }

    div.content-paragraphs {
        padding: 45px 15px;
        transition: .5s;
        @media screen and (max-width: $size-max-m-1) {
            padding: 45px 20px;
            transition: .5s;
        }

        p {
            color: $color-black;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            margin-block-start: 05px;
            transition: .5s;
            @media screen and (max-width: $size-max-m-2) {
                text-align: center;
                transition: .5s;
            }
        }
    }

    div.content-btn-recptcha {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 32px;

        div.form-group {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;


            .btn-submit {
                width: 300px;
                #submit {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 5px;
                    border: 0;
                    background: $color-black;
                    transition: .5s;
                    opacity: 1;
                    &:hover {
                        transition: .5s;
                        color: $color-yellow;
                    }
                    span {
                        width: 100%;
                        height: 55px;
                        border: 1px solid $color-white;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

section.section-emplois-jobillico {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: transparent;
    padding: 75px 0;
    div.container-fluid {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: .5s;
        width: 80%;
        @media screen and (max-width: $size-max-2) {
            width: 85%;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                width: 90%;
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {
                    width: 100%;
                    transition: .5s;
                }
            }
        }

        div#jobillico-widget-box {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
    }
}

div.bar-separator-bg {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url(../images/texture_strip_diamond_plate.jpg) no-repeat center center;
    background-size: 00;
    height: 60px;
    width: 100%;
    padding: 0;
    margin: 0;
}

.filedoc {
    width: 300px;
    height: 65px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-black;
    text-transform: uppercase;
    transition: .5s;
    cursor: pointer;
    &:hover {
        background: $color-yellow-2;
        transition: .5s;
    }
    span {
        width: 100%;
        height: 100%;
        font-size: 20px;
        color: $color-white;
        text-align: center;
        border: 1px solid $color-white;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

div.content-first-form {
    .form-control {
        height: 50px;
        border-radius: 1px;
        font-size: 18px;
    }
}

div.help-block.with-errors {
    position: absolute;
    line-height: 25px;
    margin: 0;
    color: #dc6563;
    ul {
        li {
            font-size: 12px;
            // margin-top: -5px;
            line-height: 18px;
        }
    }
}

div.help-block li {
    color: #dc6563!important;
}

section.first-form-01-online {
    input.form-control {
        border-radius: 1px;
        height: 45px;
        font-size: 18px;
    }

    section.content-form-form-1-conditions {
        transition: .5s;
        @media screen and (max-width: $size-max-m-2) {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            transition: .5s;
        }
    }

    .form-group {
        padding: 0;
        &:nth-child(2) {
            padding: 0 5px;
            transition: .5s;
            @media screen and (max-width: $size-max-m-2) {
                padding: 0;
                transition: .5s;
            }
        }
        &:nth-child(3) {
            padding: 0 5px;
            transition: .5s;
            @media screen and (max-width: $size-max-m-2) {
                padding: 0;
                transition: .5s;
            }
        }
    }
}

div.content-btn-and-dubmit-bouton {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 32px;

    div.form-group {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .btn-submit {
            width: 300px;
            #submit {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px;
                border: 0;
                background: $color-black;
                transition: .5s;
                opacity: 1;
                &:hover {
                    transition: .5s;
                    color: $color-yellow;
                }
                span {
                    width: 100%;
                    height: 55px;
                    border: 1px solid $color-white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 20px;
                }
            }
        }
    }

    div.help-block.with-errors {
        position: relative;
    }
}

section.section-004-emplois {
    display: flex;
    padding: 85px 0;
    background: $color-yellow;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: url(../images/location_machinerie_lourde_section_02_bg.jpg) no-repeat center center;
    background-attachment: fixed;
    background-size: cover;

    div.container-fluid {
        margin-bottom: 35px;
        transition: .5s;
        width: 80%;
        @media screen and (max-width: $size-max-2) {
            width: 90%;
            transition: .5s;
            @media screen and (max-width: $size-max-3) {
                width: 95%;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    width: 100%;
                    transition: .5s;
                }
            }
        }
        &:nth-child(2) {
            margin-bottom: 0;
            transition: .5s;
            width: 80%;
            @media screen and (max-width: $size-max-2) {
                width: 90%;
                transition: .5s;
                @media screen and (max-width: $size-max-3) {
                    width: 95%;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        width: 100%;
                        transition: .5s;
                    }
                }
            }
        }
    }

    div.bottom-section-with-img {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        transition: .5s;
        @media screen and (max-width: $size-max-m-1) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            transition: .5s;
        }
    }
    div.left-side-img-content {
        width: 55%;
        transition: .5s;
        transition: .5s;
        @media screen and (max-width: $size-max-m-1) {
            width: 100%;
            transition: .5s;
        }
        img {
            border-bottom: 10px solid $color-2;
            transition: .5s;
        }
    }
    div.content-rigt-side-lists {
          width: 45%;
          transition: .5s;
          @media screen and (max-width: $size-max-m-1) {
            width: 100%;
            transition: .5s;
        }
    }

    div.content-rigt-side-lists {
        padding: 0;
        transition: .5s;
        padding-left: 4vw;
        @media screen and (max-width: $size-max-1) {
            padding-left: 3vw;
            transition: .5s;
            @media screen and (max-width: $size-max-3) {
                padding-left: 2vw;
                //padding-right: 2vw;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    margin-top: 45px;
                    transition: .5s;
                    padding: 0;
                }
            }
        }
    }

    div.content-block-unique {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 0;
        @media screen and (max-width: $size-max-m-1) {
            justify-content: center;
            align-items: center;
            transition: .5s;
        }
        &:nth-child(1) {
            height: 20%;
            .title-section-004 {
                &:nth-child(1) {
                    margin-top: 0;
                    padding-top: 0;
                }
                &:nth-child(2) {
                    margin-top: 1.5vw;
                }
            }
        }
        &:nth-child(2) {
            height: 80%;
            justify-content: flex-end;
            padding-left: 0;
            @media screen and (max-width: $size-max-1) {
                height: 80%;
                transition: .5s;
                @media screen and (max-width: $size-max-2) {
                    //padding-left: 1.5vw;
                    transition: .5s;
                }
            }
        }
        h3 {
            margin-bottom: 0;
            text-transform: none;
            color: $color-black;
            font-weight: 600;
            font-size: 34px;
            width: 100%;
            transition: .5s;
            @media screen and (max-width: $size-max-1) {
                font-size: 28px;
                transition: .5s;
            }
            @media screen and (max-width: $size-max-2) {
                @media screen and (max-width: $size-max-3) {
                    font-size: 28px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-4) {
                        font-size: 24px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            font-size: 22px;
                            transition: .5s;
                            text-align: center;
                        }
                    }
                }
            }
            &.title-section-004 {
                color: $color-2;
                margin-top: 15px;
            }
        }

        h4 {
            text-transform: none;
            color: $color-2;
            font-weight: 500;
            font-size: 28px;
            width: 100%;
            @media screen and (max-width: $size-max-2) {
                font-size: 25px;
                transition: .5s;
                @media screen and (max-width: $size-max-3) {
                    font-size: 24px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-4) {
                        font-size: 22px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            font-size: 20px;
                            transition: .5s;
                            text-align: center;
                        }
                    }
                }
            }
        }

        p {
            text-align: justify;
            color: $color-black;
            font-size: 22px;
            line-height: 30px;
            font-weight: 400;
            margin-top: 5px;
            @media screen and (max-width: $size-max-2) {
                font-size: 20px;
                transition: .5s;
                @media screen and (max-width: $size-max-3) {
                    font-size: 18px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-4) {
                        //font-size: 24px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            font-size: 16px;
                            transition: .5s;
                            text-align: center;
                        }
                    }
                }
            }
        }

        ul {
            margin-top: 30px;
            margin-left: 20px;
            transition: .5s;
            margin-bottom: 0;
            padding: 0;
            @media screen and (max-width: $size-max-2) {
                margin-top: 10px;
                transition: .5s;
                @media screen and (max-width: $size-max-3) {
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        margin: 0;
                        padding: 0;
                        margin-left: 20px;
                        margin-top: 10px;
                        transition: .5s;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        margin-bottom: 0;
                    }
                }
            }
            h3 {
                margin-left: -20px;
                margin-bottom: 25px;
            }

            li {
                text-transform: none;
                color: $color-black;
                font-size: 22px;
                line-height: 35px;
                margin-bottom: 5px;
                font-weight: 500;
                @media screen and (max-width: $size-max-1) {
                    font-size: 20px;
                    line-height: 2vw;
                    transition: .5s;
                    margin-bottom: 0;
                }
                @media screen and (max-width: $size-max-2) {
                    font-size: 18px;
                    line-height: 2.4vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 18px;
                        //line-height: 28px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-4) {
                            font-size: 16px;
                            //line-height: 25px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                text-align: center;
                                line-height: 25px;
                                transition: .5s;
                            }
                        }
                    }
                }
            }
        }
    }
}

section.section-content-form-job {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    div.header-form-postule {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 75px;
         h3 {
             text-align: center;
             font-weight: 500;
             font-size: 28px;
             text-transform: none;
             color: $color-black;
         }
    }

    .content-btns-recaptcha {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .form-group {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        #submit {
            width: 300px;
            //height: 45px;
            text-align: center;
            font-size: 20px;
            background: $color-2;
            transition: .5s;
            border: 0;
            &:hover {
                background: $color-3;
                transition: .5s;
                color: $color-white;
            }
        }
    }

    div.form-offert-job {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        border-top: 1px solid $color-grey-light-2;
        padding-top: 75px;
        padding-bottom: 75px;
        width: 90%;

        .form-control {
            height: 45px;
            border-radius: 1px;
            font-size: 18px;
        }

        .form-group {
            label {
                font-size: 18px;
                font-weight: 400;
            }
        }
    }
}

.full-text {
    display: none;
}

.voir-moins,
.voir-plus {
    font-size: 18px;
    color: $color-2;
    cursor: pointer;
    transition: .5s;
    &:hover {
        color: $color-3;
        transition: .5s;
    }
}

section.section-form-map {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: url(../images/main_bg_parallax.jpg) no-repeat center center;
    background-attachment: fixed;
    background-size: cover;

    div.container-fluid.row {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        width: 100%;
        transition: .5s;
        @media screen and (max-width: $size-max-m-2) {
            flex-direction: column;
            transition: .5s;
        }

        div.left-side-infps,
        div.right-side-map {
            width: 50%;
            @media screen and (max-width: $size-max-m-2) {
                width: 100%;
                transition: .5s;
            }
        }

        div.left-side-infps {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;

            min-height: 650px;
            padding: 0 17vw;
            padding-right: 5vw;
            transition: .5s;
            @media screen and (max-width: $size-max-1) {
                padding: 0 14vw;
                padding-right: 5vw;
                transition: .5s;
            }
            @media screen and (max-width: $size-max-2) {
                padding: 0 14vw;
                padding-right: 5vw;
                transition: .5s;
                @media screen and (max-width: $size-max-3) {
                    padding: 0 12vw;
                    padding-right: 5vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-4) {
                        padding: 0 8vw;
                        padding-right: 5vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            padding: 0 5vw;
                            padding-right: 5vw;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-2) {
                                justify-content: center;
                                padding: 15px;
                                align-items: center;
                                transition: .5s;
                            }
                        }
                    }
                }
            }

            div.content-infos-address {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                padding: 0;
                padding-bottom: 15px;
                border-bottom: 1px solid $color-yellow;
                margin-bottom: 25px;
                &:nth-last-child(1) {
                    border: 0;
                }


                h3 {
                    color: $color-yellow;
                }

                .title-top {
                    text-align: right;
                    font-size: 32px;
                    line-height: 35px;
                    font-weight: 500;
                    width: 100%;
                    transition: .5s;
                    @media screen and (max-width: $size-max-2) {
                        font-size: 30px;
                        line-height: 35px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            font-size: 28px;
                            line-height: 32px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-4) {
                                font-size: 25px;
                                line-height: 32px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-1) {
                                    font-size: 22px;
                                    line-height: 25px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-2) {
                                        text-align: center;
                                        transition: .5s;
                                    }
                                }
                            }
                        }
                    }
                }

                .local-adress {
                    text-align: right;
                    font-size: 22px;
                    line-height: 30px;
                    font-weight: 400;
                    color: $color-white;
                    text-transform: none;
                    @media screen and (max-width: $size-max-2) {
                        font-size: 22px;
                        line-height: 32px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            font-size: 20px;
                            line-height: 30px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-4) {
                                font-size: 18px;
                                line-height: 25px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-1) {
                                    font-size: 16px;
                                    line-height: 22px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-2) {
                                        width: 100%;
                                        text-align: center;
                                        transition: .5s;
                                    }
                                }
                            }
                        }
                    }
                }

                .phone-number {
                    @media screen and (max-width: $size-max-m-2) {
                        text-align: center;
                        transition: .5s;
                        width: 100%;
                    }
                    a {
                        color: $color-yellow;
                        font-size: 35px;
                        font-weight: 400;
                        transition: .5s;
                        @media screen and (max-width: $size-max-2) {
                            font-size: 30px;
                            line-height: 35px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-3) {
                                font-size: 28px;
                                line-height: 35px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-4) {
                                    font-size: 25px;
                                    line-height: 35px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-1) {
                                        font-size: 25px;
                                        line-height: 35px;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-2) {
                                            width: 100%;
                                            text-align: center;
                                            transition: .5s;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &:hover {
                        a {
                            color: $color-white;
                            transition: .5s;
                        }
                    }
                    .email {
                        text-transform: none;
                        color: $color-yellow;
                        font-size: 22px;
                        font-weight: 400;
                        transition: .5s;
                        @media screen and (max-width: $size-max-2) {
                            font-size: 22px;
                            line-height: 20px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-3) {
                                font-size: 20px;
                                line-height: 35px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-4) {
                                    font-size: 20px;
                                    line-height: 35px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-1) {
                                        font-size: 20px;
                                        line-height: 35px;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-2) {
                                            width: 100%;
                                            text-align: center;
                                            transition: .5s;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                label {
                    color: $color-white;
                    font-size: 22px;
                    font-weight: 400;
                    line-height: 30px;
                    text-align: right;
                    margin-top: 15px;
                    width: 100%;
                    @media screen and (max-width: $size-max-2) {
                        font-size: 22px;
                        line-height: 32px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            font-size: 20px;
                            line-height: 30px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-4) {
                                font-size: 18px;
                                line-height: 25px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-1) {
                                    font-size: 16px;
                                    line-height: 22px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-2) {
                                        text-align: center;
                                        transition: .5s;
                                    }
                                }
                            }
                        }
                    }
                    &:nth-child(3) {
                        margin-top: 0;
                    }
                }

                .title-horaire {
                    color: $color-yellow;
                    font-size: 32px;
                    font-weight: 400;
                    transition: .5s;
                    @media screen and (max-width: $size-max-2) {
                        font-size: 30px;
                        line-height: 35px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            font-size: 28px;
                            line-height: 35px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-4) {
                                font-size: 25px;
                                line-height: 35px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-1) {
                                    font-size: 25px;
                                    line-height: 35px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-2) {
                                        width: 100%;
                                        text-align: center;
                                        transition: .5s;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .border-none {
                border: 0 !important;
            }

            h3.big-message {
                width: 100%;
                padding: 0;
                text-align: right;
                color: $color-yellow;
                font-size: 32px;
                font-weight: 400;line-height: 42px;
                margin-top: -15px;
                transition: .5s;
                small {
                    color: $color-yellow;
                    transition: .5s;
                }
                @media screen and (max-width: $size-max-2) {
                    font-size: 30px;
                    line-height: 32px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 28px;
                        line-height: 30px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-4) {
                            font-size: 25px;
                            line-height: 25px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                font-size: 22px;
                                line-height: 22px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-2) {
                                    text-align: center;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

section.section-form-contact-page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: $color-yellow;
    background: url(../images/location_machinerie_lourde_section_02_bg.jpg) no-repeat center center;
    background-attachment: fixed;
    background-size: cover;
    padding-bottom: 65px;
    transition: .5s;

    div.header-section-form-contact {
        width: 100%;
        padding: 85px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h3 {
            color: $color-black;
            font-size: 32px;
            font-weight: 500;
            padding: 15px 30px;
            //border: 2px solid $color-black;
            @media screen and (max-width: $size-max-2) {
                line-height: 32px;
                font-size: 28px;
                transition: .5s;
                @media screen and (max-width: $size-max-3) {
                    line-height: 32px;
                    font-size: 25px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-4) {
                        line-height: 32px;
                        font-size: 22px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            line-height: 28px;
                            font-size: 20px;
                            transition: .5s;
                        }
                    }
                }
            }
        }
    }

    div.body-section-form-contact {
        div.section-form {
            div.form-group {
                label {
                    font-size: 18px;
                    font-weight: 400;
                    color: $color-black;
                }

                .form-control {
                    border-radius: 1px;
                    height: 45px;
                    font-size: 18px;
                }
            }
        }

        #message {
            min-height: 220px;
            resize: vertical;
            font-size: 18px;
        }

        .select-objects {
            height: 45px;
            border-radius: 1px;
            background: $color-white;
            font-size: 18px;
            padding: 10px 10px;
            width: 100%;
        }
    }
}

section.section-03-communautes {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: $color-black-2;

    div.container-fluid {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 85px 25px;
        width: 100%;

        .accordion-container{
            position: relative;
            max-width: 100%;
            height: auto;
            margin: 10px auto;
            width: 100%;
        }

        // .accordion-container > h2{
        //     text-align: center;
        //     color: #fff;
        //     padding-bottom: 5px;
        //     margin-bottom: 20px;
        //     padding-bottom: 15px;
        //     border-bottom: 1px solid #ddd;
        // }

        div.content-acrodeon {
            display: flex;
            justify-content: space-around;
            align-items: flex-start;
            flex-wrap: wrap;
            @media screen and (max-width: $size-max-m-1) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transition: .5s;
            }
            div.one-elt-block {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 45px;
                width: 470px;
                @media screen and (max-width: $size-max-m-4) {
                    width: 300px;
                    transition: .5s;
                }

                div.header-block {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: flex-start;
                    border-top: 1px solid $color-yellow;
                    border-bottom: 1px solid $color-yellow;
                    padding: 15px 0;
                    padding-bottom: 0;
                    @media screen and (max-width: $size-max-m-1) {
                        align-items: center;
                        transition: .5s;
                    }

                    h3 {
                        color: $color-white;
                        font-weight: 500;
                        font-size: 32px;
                        margin-bottom: 10px;
                        text-transform: uppercase;
                        transition: .5s;
                        @media screen and (max-width: $size-max-2) {
                            font-size: 28px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-3) {
                                font-size: 28px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-4) {
                                    font-size: 25px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-1) {
                                        font-size: 22px;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-2) {
                                            font-size: 18px;
                                            transition: .5s;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    h4 {
                        color: $color-yellow;
                        font-weight: 500;
                        font-size: 28px;
                        margin-bottom: 10px;
                        text-transform: none;
                        @media screen and (max-width: $size-max-2) {
                            font-size: 25px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-3) {
                                font-size: 22px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-4) {
                                    font-size: 20px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-1) {
                                        font-size: 18px;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-2) {
                                            font-size: 16px;
                                            transition: .5s;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                div.body-block {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: flex-start;
                    padding: 25px 0;
                    border-bottom: 1px solid $color-yellow;
                    @media screen and (max-width: $size-max-m-1) {
                        align-items: center;
                        transition: .5s;
                    }

                    h3 {
                        color: $color-white;
                        font-size: 30px;
                        line-height: 25px;
                        font-weight: 300;
                        @media screen and (max-width: $size-max-2) {
                            font-size: 28px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-3) {
                                font-size: 28px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-4) {
                                    font-size: 25px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-1) {
                                        font-size: 22px;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-2) {
                                            font-size: 18px;
                                            transition: .5s;
                                        }
                                    }
                                }
                            }
                        }
                        a {
                            font-weight: 300;
                            font-size: 32px;
                            color: $color-white;
                            transition: .5s;
                            @media screen and (max-width: $size-max-2) {
                                font-size: 28px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-3) {
                                    font-size: 28px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-4) {
                                        font-size: 25px;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-1) {
                                            font-size: 22px;
                                            transition: .5s;
                                            @media screen and (max-width: $size-max-m-2) {
                                                font-size: 18px;
                                                transition: .5s;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &:hover {
                            a {
                                color: $color-yellow;
                                transition: .5s;
                            }
                        }
                    }

                    a.email-user {
                        color: $color-white;
                        font-size: 20px;
                        line-height: 25px;
                        text-transform: none;
                        margin-top: 10px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-2) {
                            font-size: 20px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-3) {
                                font-size: 18px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-4) {
                                    font-size: 18px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-1) {
                                        font-size: 16px;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-2) {
                                            font-size: 16px;
                                            transition: .5s;
                                        }
                                    }
                                }
                            }
                        }
                        &:hover {
                            color: $color-yellow;
                            transition: .5s;
                        }
                    }
                }
            }
        }

        .title-header {
            color: $color-yellow;
            font-weight: 600;
            text-align: center;
            line-height: 35px;
            font-size: 32px;
            text-transform: uppercase;
            margin-bottom: 32px;
            transition: .5s;
            @media screen and (max-width: $size-max-2) {
                line-height: 32px;
                font-size: 28px;
                transition: .5s;
                @media screen and (max-width: $size-max-3) {
                    line-height: 32px;
                    font-size: 25px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-4) {
                        line-height: 32px;
                        font-size: 22px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            line-height: 28px;
                            font-size: 20px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-4) {
                                font-size: 19px;
                                transition: .5s;
                            }
                        }
                    }
                }
            }
        }

        .set{
            position: relative;
            width: 100%;
            height: auto;
            //background-color: #f5f5f5;
            margin-bottom: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        .set > a{
            display: block;
            padding: 25px 12px;
            text-decoration: none;
            color: $color-white;
            font-weight: 600;
            border-bottom: 1px solid #ddd;
            -webkit-transition:all 0.2s linear;
            -moz-transition:all 0.2s linear;
            transition:all 0.2s linear;
            text-align: center;
            background: transparent;
            border: 2px solid $color-white;
            width: 300px;
            margin-bottom: 15px;
            transition: .5s;
            @media screen and (max-width: $size-max-2) {
                padding: 20px 40px;
                transition: .5s;
                @media screen and (max-width: $size-max-3) {
                    padding: 15px 30px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-4) {
                        padding: 15px 30px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            padding: 15px 30px;
                            transition: .5s;
                        }
                    }
                }
            }

            span {
                //padding: 25px 25px;
                font-size: 22px;
                text-align: center;
                transition: .5s;
                @media screen and (max-width: $size-max-2) {
                    //padding: 20px 40px;
                    font-size: 20px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        //padding: 15px 30px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-4) {
                            //padding: 15px 30px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                //padding: 15px 30px;
                                transition: .5s;
                            }
                        }
                    }
                }
            }
        }

        .set > a i{
            float: right;
            margin-top: 2px;
        }

        .set > a.active{
            background-color: $color-yellow;
            color: $color-black;
            // border: 2px solid $color-black;
        }

        .content{
            background-color: transparent;
            border-bottom: 0 solid #ddd;
            display:none;
            width: 100%;
            margin-top: 45px;
            margin-bottom: 45px;
        }

        .content p {
            padding: 10px 15px;
            margin: 0;
            color: #333;
        }
    }
}


/////// FIRST PAGE
section.first-form-tranp-spec {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;

    div.border-accepted-bouton {
        padding: 15px;
        border: 1px solid $color-grey-light-2;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    div.form-group {
        transition: .5s;
        // @media screen and (max-width: $size-max-m-2) {
        //     padding: 25 15px;
        //     transition: .5s;
        //     input {
        //         width: 100%;
        //     }
        // }
    }

    /* The container */
    .container {
        display: block;
        position: relative;
        padding-left: 35px;
        padding-top: 5px;
        margin-bottom: 5px;
        cursor: pointer;
        font-size: 15px;
        font-weight: 400;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        transition: .5s;
    }

    /* Hide the browser's default checkbox */
    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        transition: .5s;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
        transition: .5s;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
        background-color: $color-grey;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
        transition: .5s;
    }

    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
        display: block;
        transition: .5s;
    }

    /* Style the checkmark/indicator */
    .container .checkmark:after {
        left: 4px;
        top: 4px;
        width: 6px;
        height: 10px;
        border: solid $color-yellow;
        border-width: 0 17px 17px 0;
        // -webkit-transform: rotate(45deg);
        // -ms-transform: rotate(45deg);
        //transform: rotate(45deg);
        transition: .5s;
    }
}

div.bar-top-underconstructions {
    width: 100%;
    padding: 0;
    margin: 0;
    height: 60px;
    background: url(../images/texture_strip_tape.jpg) no-repeat center center;
    background-size: cover;
    padding-top: 75px;
    transition: .5s;
}

section.section-form-ouverture-compte {
    padding: 85px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $color-yellow;
    background: url(../images/location_machinerie_lourde_section_02_bg.jpg) no-repeat center center;
    background-size: cover;
    margin-top: 0;
    @media screen and (max-width: $size-max-m-1) {
        background-attachment: fixed;
        transition: .5s;
    }
    div.header-setion-compte {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 75px;

        h3 {
            color: $color-black;
            font-weight: 500;
            font-size: 30px;
            text-align: center;
            @media screen and (max-width: $size-max-2) {
                font-size: 28px;
                transition: .5s;
                @media screen and (max-width: $size-max-3) {
                    font-size: 25px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-4) {
                        font-size: 22px;
                        transition: .5s;
                    }
                }
            }
        }
    }



    div.border-accepted-bouton {
        padding: 15px;
        border: 1px solid $color-white;
        justify-content: flex-start;
        margin-bottom: 45px;
        align-items: center;
        display: flex;
        width: 100%;


        /* The container */
        .container {
            display: block;
            position: relative;
            padding-left: 35px;
            padding-top: 5px;
            margin-bottom: 5px;
            cursor: pointer;
            font-size: 15px;
            font-weight: 500;
            color: $color-black;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            transition: .5s;
            width: 80%;
        }

        a.link-see-conditions {
            font-weight: 600;
            color: $color-2;
            transition: .5s;
            &:hover {
                color: $color-black;
                transition: .5s;
            }
        }

        /* Hide the browser's default checkbox */
        .container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            transition: .5s;
        }

        /* Create a custom checkbox */
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: #eee;
            transition: .5s;
        }

        /* On mouse-over, add a grey background color */
        .container:hover input ~ .checkmark {
            background-color: #ccc;
        }

        /* When the checkbox is checked, add a blue background */
        .container input:checked ~ .checkmark {
            background-color: $color-grey;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
            transition: .5s;
        }

        /* Show the checkmark when checked */
        .container input:checked ~ .checkmark:after {
            display: block;
            transition: .5s;
        }

        /* Style the checkmark/indicator */
        .container .checkmark:after {
            left: 4px;
            top: 4px;
            width: 6px;
            height: 10px;
            border: solid $color-yellow;
            border-width: 0 17px 17px 0;
            // -webkit-transform: rotate(45deg);
            // -ms-transform: rotate(45deg);
            //transform: rotate(45deg);
            transition: .5s;
        }
    }
}

#anchor {
    padding-top: 10px;
}

.image-link {
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
}

/* aligns caption to center */
.mfp-title {
    text-align: center;
    padding: 6px 0;
}

.text-center {
    text-align: center!important;
}

.title-empty {
    width: 100%;
    color: #ccc;
    font-size: 20px;
    text-transform: none;
    font-weight: 400;
    text-align: center;
}


/*****************************************************/
/* PAGE GALERIE
/*****************************************************/

#blocGalerie {
    width: 30%;
    margin: 47px;

    &:nth-child(3n+2) {
        margin-left: 0;
        margin-right: 0;
    }
}






/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or module-menu-centre.php
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';


/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
@import 'sections/section-header-page';

@import 'sections/section-pagination';

@import 'sections/section-fsb-gallery';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

@import 'sections/section-projets';

/*****************************************************/
/* Sections (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-soumission';
//@import 'modules/module-header';





/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1879px) {

#blocGalerie {
    margin: 40px;
}
}

@media screen and (max-width: 1590px) {

#blocGalerie {
    width: 29%;
    margin: 45px;
}
}

@media screen and (max-width: 1380px) {

#blocGalerie {
    width: 28%;
    margin: 50px;
}
}

@media screen and (max-width: 1250px) {

#blocGalerie {
    width: 24%;
    margin: 65px;
}
.section-projets {
    justify-content: center;
}
}

@media screen and (max-width: $size-md-max) {


}

@media screen and (max-width: 1105px) {

section.section-01-index div.container-fluid.row div.left-side-img div.content-img-left.first-top img.img-rsponsive.imgLeft {
    padding-right: 65px;
}
}

@media screen and (max-width: 1024px) {

#blocGalerie {
    width: 30%;
    margin: 25px;
}
section.section-01-index div.container-fluid.row div.left-side-img div.content-img-left.first-top img.img-rsponsive.imgLeft {
    padding-right: 40px;
}
}

@media screen and (max-width: $size-sm-max) {

#blocGalerie {
    width: 50%;
    margin: 30px 0;
}
}

@media screen and (max-width: $size-xs-max) {

#blocGalerie, #blocGalerie:nth-child(3n+2) {
    width: 100%;
    margin: 30px;
}
section.section-01-index div.container-fluid.row div.right-side-text div.content-icons-bottom {
    flex-direction: column;
}
section.section-01-index div.container-fluid.row div.left-side-img div.content-img-left.first-top {
    flex-direction: column;
}
section.section-01-index div.container-fluid.row div.left-side-img div.content-img-left.first-top img.img-rsponsive.imgLeft {
    padding-bottom: 30px;
    padding-right: 0;
}
}
