$input_height: 50px;
$input_back_color: #fff;
$input_text_color: #000;
$input_text_size: 20px;

$input_placeholder_color: $color-text-gris;
$input_placeholder_size: 18px;

$input_border_color: $color-primary;
$input_border_radius: 0;
$input_border_size: 1px;
$input_border_style: solid;

$input_margin: 10px 0;
$input_text_color: $color-white;
$input_label_color: $color-white;
$label_checkbox_border_color: $label-color;
$input_checked_color: $color-primary;

$textarea_height: 200px;

$message_errors_text_color: white;
$message_errors_border_color: hsla(0, 100%, 55%, 1);
$message_errors_border_size: 5px;
$message_errors_border_radius: 5px;
$message_errors_background_color: hsla(0, 100%, 55%, 0.7);
$message_success_text_color: white;
$message_success_border_color: hsla(120, 100%, 50%, 1);
$message_success_border_size: 5px;
$message_success_border_radius: 5px;
$message_success_background_color: hsla(120, 100%, 50%, 0.5);
//
//form {
//    input.form-control, textarea.form-control {
//        height: $input_height;
//        color: $input_text_color;
//        font-size: $input_text_size;
//        background-color: $input_back_color;
//        border: $input_border_size $input_border_style $input_border_color;
//        margin: $input_margin;
//    }
//    input::placeholder, textarea::placeholder {
//        font-size: $input_placeholder_size;
//        color: $input_placeholder_color;
//    }
//    .title {
//        text-transform: uppercase;
//        color: $input_text_color;
//        font-weight: bold;
//        margin-top: 1em;
//    }
//    .center {
//        text-align: center;
//        margin-left: auto;
//        margin-right: auto;
//    }
//    .radios, .checkboxes {
//        width: 100%;
//        margin: 20px 0 20px 0;
//        display: flex;
//        align-items: center;
//        justify-content: space-between;
//        input[type="radio"], input[type="checkbox"] {
//            position: absolute;
//            visibility: hidden;
//            &:checked+label:before {
//                background-color: $input_checked_color;
//            }
//        }
//        input[type="radio"] {
//            &+label:before {
//                border-radius: 100%;
//            }
//        }
//        label {
//            display: inline-block;
//            cursor: pointer;
//            color: $input_label_color;
//            position: relative;
//            padding: 6px 5px 6px 35px;
//            font-size: $input_text_size;
//            transition: all .3s ease;
//            &:before {
//                content: "";
//                display: inline-block;
//                width: $input_text_size;
//                height: $input_text_size;
//                position: absolute;
//                left: 0px;
//                background: none;
//                border: 2px solid $label_checkbox_border_color;
//            }
//        }
//    }
//    .radios {
//        justify-content: space-between;
//    }
//    textarea {
//        margin: 2em 0;
//        height: $textarea_height !important;
//    }
//    .buttons {
//        display: flex;
//        justify-content: space-between;
//        margin: $input_height 0;
//        label.button, button#submit {
//            border: $input_border_size $input_border_style $input_border_color;
//            border-radius: $input_border_radius;
//        }
//    }
//}
//
//form input.form-control, form textarea.form-control {
//    border: 1px solid #CCC!important;
//}
//
//.messages {
//    padding: 30px;
//    margin-bottom: 2em;
//    &.errors, &.success {
//        p {
//            font-size: 24px;
//            line-height: 1;
//            margin: 0
//        }
//    }
//    &.errors {
//        background-color: $message_errors_background_color;
//        border-radius: $message_errors_border_radius;
//        border: $message_errors_border_size solid $message_errors_border_color;
//        color: $message_errors_text_color;
//        p {
//            color: $message_errors_text_color;
//        }
//    }
//    &.success {
//        background-color: $message_success_background_color;
//        border-radius: $message_success_border_radius;
//        border: $message_success_border_size solid $message_success_border_color;
//        color: $message_success_text_color;
//        p {
//            color: $message_success_text_color;
//        }
//    }
//}

div.loading {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display:none;
    background-color: rgba(0,0,0,0.7);
    background-size: cover;
    z-index: 9999;
    &.active {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

#loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border: 16px solid $bg-color-body;
    border-radius: 50%;
    border-top: 16px solid $color-secondary;
    border-bottom: 16px solid $color-primary;
    width: 120px;
    height: 120px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.8), inset 0px 0px 5px rgba(0,0,0,0.8);
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
